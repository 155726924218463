export default {
    basic: {
        // 공용
        public: {
            workbench: "작업대 전환",
            clearSear: "조회 조건 비우기",
            refresh: "테이블 새로 고침",
            search: "검색",
            // 회사
            entitySearch: "회사 이름 검색",
            entityID: "회사ID",
            entityName: "회사 이름",
            entityAddr: "회사 주소",
            // tble 틀내의 장소를 드롭다운
            siteID: "장소ID",
            siteName: "장소 이름",
            other: "장소 주소",
            siteSearchPla: "장소 이름을 입력하십시오.",
            // tble 틀내의 유형을 드롭다운
            typeSearch: "유형 이름을 입력하십시오.",
            typeID: "유형 ID",
            typeName: "유형 이름",
            typeOther: "유형 번호",
            typeTitle: "설비 유형",
            // 모델
            modelSearch: "모델 이름을 입력하십시오.",
            modelID: "모델ID",
            modelName: "모델 이름",
            modelOther: "모델 코드",
            // 모형
            Two_modelSearch: "모형 이름을 입력하십시오.",
            Two_modelID: "모형ID",
            Two_modelName: "모형 이름",
            Two_modelOther: "생성 시간",

            Unblock: "차단 취소",
            sureUnblock: "경고 차단을 취소하시겠습니까?",

            // 작업
            details: "상세 내용",
            controls: "작업",
            confirmBtn: "확정",
            cancel: "취소",
            confirmNospace: "확정",
            cancelNospace: "취소",
            controls_collect: "수집",
            cancelOperation: "작업 취소",
            addBtn: "신규",
            delete: "삭제",

            //  규칙
            rulesSpace: "입력에 공백을 포함할 수 없습니다.",
            rule_entityNospace: "회사 ID는 비워둘 수 없습니다.",
            basicInformation: "기본 정보",

            open: "켜기",
            close: "끄기",
            closeSpace: "끄기",
            Time_midden: "까지",
            Time_start: "시작 날짜",
            Time_end: "종료 날짜",
            create_date: "생성 날자",
            MobilePhone: "휴대폰 번호",
            sendingTime: "발송 시간",
            eventType: "사건 유형",
            deviceCoding: "설비 번호",
            installAddr: "설치 위치",
            alarmReason: "경보 원인",
            alarmTiem: "경보 시간",
            searchDeviceCoding: "설비 번호를 입력하십시오. ",
            moreSear: "더 많은 검색",
            owner: "소유자",
            owner_phone: "전화",
            alarm_type: "경보 유형",

            notAllowBatch: "대량 삭제는 허용되지 않습니다!",

            //messageAll
            message_addSucce: "신규 성공!",
            message_addLose: "신규 실패!",
            messageAllClear: "모든 조회 조건을 비웠습니다!",
            messageAllRefresh: "새로 고침 성공!",
            messageAllSear: "검색 성공!",
            message_collectSucc: "수집 성공!",
            message_collectLose: "수집 실패!",
            message_importSucc: "도출 성공!",
            message_importLose: "도출 실패!",
            message_importRule: "도출할 데이터를 먼저 선택하십시오.",
            message_deleteImgSucc: "사진 삭제 성공!",
            message_deleteImgLose: "사진 삭제 실패!",
            message_ImgUpSucc: "업로드 성공!",
            message_ImgUpLose: "업로드 실패!",
            message_editSucc: "수정 성공!",
            message_editLose: "수정 실패!",
            message_addDataSucc: "신규 성공!",
            message_addDataLose: "신규  실패!",
            message_deleteSucc: "삭제 성동!",
            message_deleteLose: "삭제 실패!",
            message_exceedMaxImg: "업로드 실패!사진 한 장만 업로드 할 수 있습니다.",

            // 파일
            file_btnAdd: "클릭하여 업로드",
            file_attachmentAdd: "첨부 업로드",
            file_isUpIMg: "이 사진을 업로드하시겠습니까?",
            file_Tips: "제시",


            // 테이블
            derive: "도출",
            deriveTips: "이 데이터를 도출하시겠습니까?",
            table_createdTime: "생성 시간",
            table_entity: "소속 회사",
            table_deviceType: "설비 유형",
            table_OwnersName: "소유자 이름",
            table_OwnerTelephone: "소유자 전화",
            table_installationPersonnel: "설치 인원",

            table_deviceModel: "설비 모델",
            table_deviceState: "설비 상태",
            table_deviceLatelyTime: "최근 활동 시간",
            table_handleMan: "처리자",
            table_handleTime: "처리 시간",
            table_handleSuggestion: "처리 의견",
            table_FaultCause: "고장 원인",
            table_faultTime: "고장 시간",
            table_informant: "신고인",
            table_faultDesc: "고장 설명",
            table_alarmRulesRadius: "경보 범위",
            table_alarmRulesEvent: "경보 사건",
            table_alarmRulesIsOpen: "켜시겠습니까",
            table_alarmRulesDevice: "경보 설비",
            table_alarmRulesEntity: "경보 회사",
            table_UpTime: "업데이트 시간",
            table_pointName: "지점 이름",
            table_pointAddr: "지점 주소",
            table_relevanceDevice: "관련 설비",
            table_entityName: "소속 회사",
            table_region: "소속 구역",

            // label 선택
            select_all: "전부",
            select_Install: "설치 됨",
            select_uninstalled: "설치되지 않음",
            // select_
            // select_
            // select_

            rule_content: "내용을 입력하십시오",
            rule_data: "데이터를 선택하십시오",

        },
        // 검색 내용
        search: {
            sitePla: "장소 이름을 입력하십시오",
            typePla: "유형 이름을 입력하십시오",
            deviceCodePla: "설비 번호를 입력하십시오.",
            ownerPla: "소유자 이름을 입력하십시오",
            ownerPhonePla: "소유자 전화번호를 입력하십시오",
            search: "검색",
            searchNoMore: "더 이상 없습니다",
            isLoading: "로드 중",
            noCorrectPhone: "휴대전화 번호가 정확하지 않습니다",
        },
        // 특별시 광역시 도 군 시 구성 요소
        region: {
            city: "소재 도를 선택하십시오",
            county: "소재 도 혹은 시를 선택하십시오",
            provincePla: "소재 도를 선택하십시오",
            cityPla: "소재 군을 선택하십시오",
            countyPla: "소재 시를 선택하십시오"
        },

        // 애플리케이션 센터
        ApplicationCenterPage: {
            title: "애플리케이션 센터",
            logOut: "로그아웃",
            backStage: "백그라운드 관리",
            NumScreen: "디지털 대형 스크린",
            alarmPlatform: "경보 접수 플랫폼",
            patrol: "순검 순찰",
            production: "생산 관리",
            childPlatform: "하위 플랫폼",
            logOutTips: "로그아웃 하시겠습니까?",
            chilListFiren: "스마트 소방 플랫폼",
            chilListGasB: '범용 가스 플랫폼',
            yes: "예",
            cancel: "취소",
            closeAlarmMute: "알람 소리 끄기",
            bgSet: "배경 설정",
            bgImg: "배경 이미지",
            bgColor: "배경 색상",
            def: "기본값",
            personSet: "개인 설정",
            bgMenuColor: "메뉴 배경 색상",
            textMenuColor: "메뉴의 글자 색상",
            acMenuTextCol: "활성화된 메뉴의 글자 색상",
            hovBgColor: "마우스 오버 시 배경 색상",
            hovTextCOlor: "마우스 오버 시 글자 색상",
            acMenuBgColor: "메뉴 활성화 시 배경 색상",
            initSet: "초기화 설정",
        },
        // 로그인 페이지
        newLogin: {
            title: "스마트 보안 클라우드 플랫폼",
            login: "로그인",
            userPla: "계정을 입력하십시오",
            userRules: "계정은 비워둘 수 없습니다",
            passwordPla: "암호를 입력하십시오",
            passwordRules: "암호는 비워둘 수 없습니다",
            passwordRulesLength: "6자 이상이여야 합니다.",
            platformTitle: "보안 백그라운드 관리",
            loginSucc: '로그인 성공!',
            loginLose: '사용자 이름이 존재하지 않거나 암호가 잘못되었습니다!',
            successText: "인증 성공!",
            failText: "인증 실패, 다시 시도해 주세요",
            sliderText: "슬라이더를 끌어서 퍼즐을 완성하세요",
        },
        // 대형 디지털 화면
        digitalPage: {
            title: "백그라운드 관리",

            deviceTitle: "설비 집계",
            deviceAllNum: "총 설비 수",
            deviceOnLine: "온라인 설비 수",
            deviceOffLIne: "오프라인 설비 수",
            deviceInstall: "설치된 설비 수",
            deviceNoInstall: "설치되지 않은 설비 수",
            leftMiddenTilt: "설비 설치 진행률",
            leftMiddenNum: "수량",
            leftMiddenpercent: "백분율",

            leftBottomTitle: "설비 유형 집계",
            leftBottomDevice: "설비",

            middenTop_todayAlarm: "오늘 경보",
            middenTop_fault: "오늘 고장",
            middenTop_install: "오늘 설치",
            middenTop_maintenance: "오늘의 유지 보수",

            middenMap_owenr: "소유자 정보",
            middenMap_owenrPhone: "소유자 전화",
            middenMap_addr: "장소 주소",
            middenMap_siteName: "장소 이름",
            middenMap_siteDeviceNum: "장소 설비 총수",
            middenMap_floorNum: "층수",
            middenMap_dilogTitle: "지점 리스트",
            enterCorrectFloor: "정확한 층을 입력해 주세요",


            middenMap_dialog_owenrName: "소유자 이름",
            middenMap_dialog_owenrPhone: "소유자 전화",
            middenMap_dialog_siteName: "장소 이름",
            middenMap_dialog_siteAddr: "장소 주소",

            middenBottom_entity: "회사 관리",
            middenBottom_jianzhu: "건물",
            middenBottom_device: "설비",
            middenBottom_user: "사용자",
            middenBottom_sys: "설치",

            rightTop_alarm: "경보",
            warning: "경고",
            rightTop_fault: "고장",

            rightMidden_title: "장치 상태 집계",
            rightMidden_offline: "오프라인",
            rightMidden_tamperd: "제거",
            rightMidden_highTemperature: "고온",
            rightMidden_low_signal: "신호 약함",
            rightMidden_lowBattery: "저전력",
            rightMidden_fault: "센서 고장",
            rightMidden_alarm: "경보",
            feifa_disassemble: "불법 제거",
            rengong_fault: "인공 보장",
            rightBottom_alarmAddr: "경보 주소",
            rightBottom_deviceNum: "비 번호",
            rightBottom_alarmType: "경보 유형",

            dialog_entityTitle: "회사 관리",
            dialog_entitySear: "회사 이름을 선택하십시오.",
            dialog_entityName: "소속 회사",
            dialog_entityAddr: "회사 주소",
            dialog_entityPlatformName: "플랫폼 이름",
            dialog_entityGo: "설비 보기",

            dialog_siteTitle: "건물 리스트",
            dialog_siteSearName: "장소 이름을 입력하십시오.",
            dialog_siteAddrSear: "장소 주소를 입력하십시오.",
            dialog_siteAddr: "장소 주소",
            dialog_siteName: "장소 이름",
            dialog_siteEntityName: "소속 회사",
            dialog_siteRegion: "소속 구역",

            dialog_deviceNum: "설비 번호를 입력하십시오.",
            dialog_deviceState: "설비 상태를 선택하십시오.",
            dialog_deviceOrder: "지령",
            dialog_deviceNumTable: "설비 번호",
            dialog_deviceEntity: "소속 회사",
            dialog_deviceType: "설비 유형",
            dialog_deviceInstallAddr: "설치 위치",
            dialog_deviceTypetwo: "설비 모델",
            dialog_deviceStateTable: "설비 상태",
            dialog_deviceEventsTime: "최근 활동 시간",

            dialog_userTitle: "사용자 리스트",
            dialog_userLoginName: "계정을 입력하십시오",
            dialog_userNameSear: "이름을 입력하십시오",
            dialog_userPhoneSear: "휴대폰 번호를 입력하십시오.",
            dialog_userEntityTitle: "회사",
            dialog_userEntityName: "소속 회사",
            dialog_userPhone: "전화",
            dialog_userSex: "성별",
            dialog_userMan: "남",
            dialog_userWoMan: "여",
            dialog_userloginTable: "계정",
            dialog_userJurisdiction: "권한",
            dialog_userName: "사용자 이름",
            dialog_userDeatil: "사용자 상세 정보 보기",
            dialog_userDeatilBasice: "기본 정보",
            dialog_userDeatilBtn: "수정 확인",


            dialog_SettingTitle: "설정",
            dialog_SettingBackdrop: "배경",
            dialog_SettingNormal: "표준 색상",
            dialog_SettingDarkblue: "나이트 블루",
            dialog_Settingblue: "인디고 남",
            dialog_SettingGrey: "아스트 그레이",
            dialog_SettingDark: "팬텀 블랙",
            dialog_SettingLight: "달빛 실버",

            dialog_deviceListTitle: "설비 리스트",

        },

        // 설비 리스트
        deviceList: {
            beginTime: "시작 시간 선택",
            endTime: "종료 시간 선택",
            timeTips: "시간 형식이 hh:mm:ss 인지 확인하세요",
            bufangSucc: "설정된 경보 시간 성공",
            bufangFail: "설정된 경보 시간 실패",
            beginBuFang: "방어진을 치다",
            cancelBuFang: "선택된 장치의 경보 시간을 성공적으로 취소했습니다",
            OperationFailed: "작업 실패",
            search_isInstall: "설치 여부",
            Locked_status: "잠금 상태",
            state_title: "설비 상태 집계",
            state_one: "모든 상태",
            state_offline: "오프라인",
            state_tamperd: "제거",
            state_highTemperature: "고온",
            state_low_signal: "신호약함",
            state_lowBattery: "저전력",
            state_mainPower: "메인 전원 고장",
            state_fault: "센서 고장",
            state_alarm: "경보",
            state_normal: "정상",
            state_batteryState: "배터리 상태",
            state_valveState: "밸브 상태",
            push_fail: "전달 실패",
            setBuFangTime: "경보 시간 설정",
            cancelBuFangTime: "경보 시간 취소",

            device_detailTitle: "설비 상세 정보",
            device_detailBasice: "기본 정보",
            device_detailInstall: "설치 정보",
            device_detailRun: "설비 운행 데이터",
            device_detailAlarm: "경보 처리",
            device_detailFault: "고장 처리",
            device_detailVideo: "비디오 모니터링",
            device_detailAlarmRule: "경보 규칙",
            device_detailLInkage: "연동 규칙",

            device_bind: "바인딩",
            device_bindTitle: "설비 바인딩",
            deviceBind_Pla: '이미 생성된 장소를 선택하거나 이 입력을 건너뛰고 아래에 새 장소를 만드십시오.',
            // 차단
            device_blockDevice: "차단",
            device_recogniseBlockDevice: "설비 경보를 차단하시겠습니까?차단 후 이 설비의 경보 알림을 더 이상 받지 않습니다!",
            device_SuccessfullyBlockedTheDevice: "이 설비를 성공적으로 차단했습니다!",
            // 지령
            device_order: "지령",
            device_orderTilte: "지령 하달",
            device_orderTips: "이 지령을 내리시겠습니까?",
            device_orderNoList: "이 설비에 잠시 지령이 없습니다. ",
            device_noRunOrder: "이 설비에 하달할 수 있는 지령이 없습니다. ",
            device_noOrder: "이 설비에 지령이 없습니다. ",
            devcie_orderSucc: "지령 하달 성공!",
            device_orderLose: "지령 하달 실패!",
            // 더 많은 작업
            device_controlsTitle: "더 많은 작업",
            device_controlsInitialize: "설비 초기화",
            device_controlsTransfer: "설비 이전",
            device_controlsRelieveBind: "바인딩 해제",
            device_controlsClearFault: "고장 제거",
            device_controlsFang: "방비",
            device_controlsCefang: "방비 해제",
            device_controlsLock: "잠금",
            device_controlsRelieveLock: "잠금 해제",
            device_controlsInitializeCont: "설비 연관 지점을 삭제하고 바인딩 해제된 상태를 복원합니다.",
            device_controlsTransferCont: "다른 회사로 설비 이동",
            device_controlsRelieveBindCont: "현재 계정과 설비의 바인딩 해제",
            device_controlsClearFaultCont: "설비의 모든 고장 제거",
            device_controlsFangCont: "설비 방비, 모든 설비 지원",
            device_controlsCefangCont: "설비 방비 해제, 모든 설비 지원",
            device_controlsLockCont: "잠금",
            device_controlsRelieveLockCont: "잠금 해제",

            device_locked: "잠김",
            device_notLock: "잠기지 않음",

            device_messgae: "작업할 설비를 선택하십시오!",
            device_controlsInitializeTips: "이 작업을 수행하면 설비 연관 지점이 삭제되고 바인딩되지 않은 상태로 복원됩니다. 계속하시겠습니까?",
            device_controlsInitializeSucc: "초기화 성공!",
            device_controlsInitializeLose: "초기화 실패!",

            device_controlsRelieveBindTips: "이 작업은 현재 계정과 설비의 바인딩을 해제합니다. 계속하시겠습니까?",
            device_controlsRelieveBindSucc: "설비 바인딩 해제 성공!",
            device_controlsRelieveBindLose: "설비 바인딩 해제 실패!",

            device_controlsClearFaultTips: "이 작업을 수행하면 설비의 모든 고장이 제거됩니다. 계속하시겠습니까?",
            device_controlsClearFaultSucc: "설비의 모든 고장를 제거하는 데 성공했습니다!",
            device_controlsClearFaultLose: " 설비의 모든 고장를 제거하는 데 실패했습니다!",

            device_controlsFangTips: "설비, 이 작업은 현재 마그네틱/적외선 연기감지기만 지원합니다. 계속 하시겠습니까",
            device_succ: "성공!",
            device_lose: "실패!",
            device_cancel: "취소됨",
            device_warn: "경보",

            device_isInfraredDefence: "방비 설정 여부",
            device_InfraredDefence_Status: "방비 상태",
            device_InfraredDefence_Status_tip: "이 설비의 방비 상태를 수정하시겠습니까?",
            device_controlsLockTips: " 설비를 잠그면 설비의 고장 데이터를 처리할 수 없습니다. 경보 및 심장 박동 데이터만 처리할 수 있습니다! 설비를 잠그시겠습니까?",
            device_isLockDevice: "잠금 여부",
            device_controlsLockDevice: "설비 잠금",
            device_controlsRelieveLockTDevice: "잠금 해제",
            device_controlsRelieveLockTips: " 잠금 해제, 설비 정상 기능 복구, 잠금 해제하시겠습니까?",

            device_controlsTransferContSucc: "장치 이전 성공!",
            device_controlsTransferContLose: "장치 이전 실패!",

            device_collectTips: "설비를 수집 하시겠습니까?",

            device_noInstall: "설비가 설치되지 않았습니다!",
            device_noCheckDeatil: "설비 상세 정보를 볼 수 없습니다!",
            device_checkLose: "설비 상세 정보 보기 실패!",
            device_Novideo: "리플레이 주소 가져오기 실패!",

            // drawer-측면 팝업 상자
            drawer_title: "설비 상세 정보",
            drawer_installNews: "설치 정보",
            drawer_runData: "설비 운행 데이터",
            drawer_alarmHandle: "경보 처리",
            drawer_faultHandle: "고장 처리",
            drawer_video: "비디오 모니터링",
            drawer_alarmRule: "경보 규칙",
            drawer_linkageRule: "연동 규칙",
            drawer_pushCondition: "전달 상황",
            drawer_BindUser: "바인딩 인원",
            drawer_eventImage: "사건 사진",

            // dialog-중심 팝업 상자
            dialog_title: "설비 이전",
            enpty_title: "회사 이름을 선택하십시오!!!",

            // 기본 정보
            basice_Title: "설비 정보",
            basice_TwoCode: "QR코드",
            basice_installTime: "설치 시간",
            basice_deviceType: "설비 유형",
            basice_model: "모델 코드",
            basice_code: "설비 코드",
            basice_edit: "수정",
            basice_ccid: "ccid",
            basice_telecomID: "이통사A ID",
            basice_moveID: "이통사B ID",
            basice_imei: "imei",
            basice_imsi: "imsi",
            basice_ToggleChart: "아이콘 전환",
            basice_toggleCar: "카드 전환",
            basice_deviceStats: "설비 속성",
            basice_signalStrength: "신호 강도",
            basice_voltage: "전압",
            basice_temperatureNum: "온도 값",
            basice_concentrationNum: "농도 값",
            basice_deviceIMg: "설비 사진",
            basice_upImg: "사진 업로드",
            basice_deleteImgTips: "설비 사진을 삭제하시겠습니까?",
            basice_chartTitle: "비 속성 추이",
            basice_temperature: "온도",
            basice_concentration: "농도",
            basice_chartDeviceNoData: "이 설비는 데이터를 보고하지 않아 속성 곡선을 보여줄 수 없습니다!",
            basice_copySucc: "복사 성공!",
            basice_copyLose: "죄송합니다. 복사에 실패했습니다.",
            basice_editSucc: "수정 성공!",
            basice_editLose: "수정 실패!",
            basice_humidness: "습도",
            basice_HumidityValue: "습도 값",
            basice_brightScreen: "화면 밝힘",
            basice_noScreen: "화면 끄기",
            basice_TemperatureUnit: "온도 단위",
            basice_DegreeCelsius: "섭씨도",
            basice_Fahrenheit: "화씨도",
            basice_ScreenState: "스크린 상태",

            basice_alarm: "경보",
            basice_cameraArgu: "카메라 파라메터",
            basice_addr: "주소",
            basice_state: "상태",
            basice_line: "온라인",
            basice_transmit: "스트리밍 모드",
            basice_NewHeartbeat: "최근 심장박동",
            basice_NewSignIn: "최근 등록",
            basice_passage: "채널 수",
            basice_bing: "설비 바인딩",
            basice_editMqtt: "mqtt 정보 수정",
            basice_editHttp: "http 정보 수정",
            basice_smoke: "433연기 감지기",
            basice_relieveBind: "바인딩 해제",
            basice_relieve: "바인딩 해제",
            basice_setFang: "방호구역 설정",
            basice_fang: "방호구역",
            basice_confirmEdit: "수정하시겠습니까?",
            basice_ipTips: " Ip 주소/도메인 이름을 입력하십시오",
            basice_userNameTIps: "사용자 이름을 입력하십시오.",
            basice_password: "암호를 입력하십시오.",
            basice_bindNameTips: "바인딩 이름을 입력하십시오.",
            basice_fangqu: "방호구역 이름을 입력하십시오.",
            basice_messageCarmes: "카메라 상세 정보 가져오기 실패!",
            basice_messageDeviceNodata: "이 설비는 데이터를 보고하지 않아 속성 곡선을 보여줄 수 없습니다.",
            basice_messageAddr: "주소는 비워둘 수 없습니다!",
            basice_messageIssueSucc: "하달 성공!",
            basice_messageIssueLose: "하달 실패!",
            basice_messageNOfang: "방호구역 이름은 비워둘 수 없습니다.",
            basice_messageIssueSetSucc: "어구 설정 하달 성공!",
            basice_messageIssueSetLose: "방어구 설정 하달 실패!",
            basice_messageIssueBind: "바인딩 명령 하달 실패!",
            basice_messageCarmesNoSmoke: "현재 카메라는 연기감지기를 마운트하지 않았습니다!",
            basice_messageInquireLose: "조회 실패",
            basice_messageInquire433Lose: "이 설비는 아직 연기감지기가 없습니다!",
            basice_messageIssueORderLose: "지령 하달 실패!",
            basice_messageNoBindLose: "바인딩 해제 지령 하달 실패!",

            basice_deviceVoltage: "설비 전안",
            basice_temperatureNew: "현장 온도",
            basice_formHUmidness: "상대 습도",
            basice_MoverNum: "이동 횟수",
            basice_signalQuality: "신호 품질",
            basice_deviceIMSi: "설비 IMSI",
            basice_getDeviceDetilTips: "설비 상세 정보 가져오기 실패!",

            // basice_
            device_data: "설비 데이터",
            set_harvest_and_cardiac_cycle: "센싱 주기 및 심장 박동 주기 설정",
            eqManage: "지하 공간 및 갱내 가스 탐지기",
            host_state: "호스트 상태",
            signal_strength: "신호 강도",
            Low_Battery_Alarm: "저전력 경보",
            Flood_Alarm: "침수 경보:",
            Alarm_for_manhole_cover_movement: "우물 뚜껑 이상 움직임 경보:",
            signal_strength_rsrp: "무선 신호 강도(rsrp):",
            signal_to_noise_ratio: "신호 잡음 비율",
            Physical_Cell_Marking: "물리적 영역 표식:",
            BTS_Cell_Marking: "기지국 영역 표식:",
            Coverage_enhancement_level: "커버레지 향상 레벨",
            XYZ_axis_angle: "XYZ 축 각도:",
            Battery_Voltage: "배터리 전압: ",
            temp: "온도:",
            humidity_level: "습도:",
            collection_period: "센싱 주기:",
            cardiac_cycle: "심장 박동 주기:",
            software_version: "소프트웨어 버전:",
            hardware_version: "하드웨어 버전:",
            collection_period_input: "입력하시겠습니까? 샘플링 주기",
            CycleUnit_input: "입력하시겠습니까? 허트비트 주기",
            // 경도
            longitude: "경도",
            // 위도
            latitude: "위도",
            // 설비 내부 압력
            Internal_equipment_pressure: "설비 내부 압력",
            // 환경 압력
            environment_stress: "환경 압력",
            sensitivity: "진동 센서 감도",
            panel_voltage: "태양광 충전판 전압",
            shake_alarm: "진동 경보(카메라)",
            eqAlarm: "저전력 경보 (카메라)",
            cell_video: "배터리 전압(카메라)",
            heartBeat_video: "심장 박동 주기(카메라)",
            soft_video: "소프트웨어 버전(카메라)",
            videoText: "카메라",
            // 설치 정보
            install_title: "장소-지점 정보",
            install_checkSiteImg: "장소 사진 보기",
            install_owner: "소유자",
            install_ownerPhone: "소유자 전화",
            install_pointName: "지점 이름",
            install_pointCont: "지점 사진 보기",
            install_pointAddr: "지점 주소",
            install_siteImg: "장소 사진",
            install_mapAddr: "지도 위치",
            install_copyLatlng: "장소 경도 위도 복사",
            install_getSiteNewsLose: "장소 정보 가져오기 실패",
            install_getPointNewsLose: "지점 정보 가져오기 실패",

            // 운행 데이터
            operation_alarmTitle: "경보 테이터",
            operation_voltage: "전압(mv)",
            operation_dataSource: "데이터 소스",
            operation_otherData: "기타 데이터",
            operation_firmCoding: "제조업체 번호",
            operation_pushModel: "모델 보내기",
            operation_pushType: "유형 전송",
            operation_pagination: "현재 페이지수",
            operation_faultTitle: "고장 데이터",
            operation_hearbeat: "심장 박동 데이터",
            operation_releaseRecord: "지령 하달 기록",
            operation_previous: "이전 페이지",
            operation_NextPage: "다음 페이지",
            operation_resultCoding: "하달 결과 번호",
            operation_resultDesc: "하달 결과 설명",

            // 경보 처리
            alarm_handle: "처리",
            alarm_succ: "처리 성공!",
            alarm_dialogTitle: "경보 처리",
            alarm_handlePla: "경보 원인를 선택하십시오.",
            alarm_systematicPro: "시스템 생성",
            alarm_faultHandle: "고장 처리",
            alarm_isFinishMonad: "마무리 하시겠습니까",
            alarm_yes: "예",
            alarm_no: "아니오",
            alarm_feedback: "피드백 내용",
            alarm_feedbackPla: "피드백 내용을 입력하십시오.",
            alarm_impor: "파일 가져오기",
            alarm_draweTitle: "고장 상세 정보",
            alarm_faultType: "고장 유형",
            alarm_handleRecords: "처리 기록",
            alarm_handleImg: "처리 사진",
            alarm_ImgCheck: "사진 상세 정보",
            alarm_MessageChcekLose: "조회 실패!",

            // 바인딩
            bind_entity: "회사",
            bind_installSite: "설치 장소",
            bind_siteName: "장소 이름",
            bind_siteNameLian: "관련 장소",
            bind_installPoint: "설치 지점",
            bind_installPointPla: "설치 지점을 입력하십시오",
            bind_isntallAddr: "설치 주소",
            bind_installAddrPla: "설치 주소를 입력하십시오.",
            bind_subBind: "지금 바인딩",
            bind_selectRegion: "소속 구역을 선택하십시오.",
            bind_siteNameLianPla: "관련 장소를 입력하십시오",
            bind_addSite: "신규 장소",
            bind_siteNamePla: "장소 이름을 입력하십시오",
            bind_siteAddr: "장소 주소을 입력하십시오",
            bind_owner: "소유자 이름을 입력하십시오",
            bind_ownerPhone: "소유자 전화를 입력하십시오",
            bind_floorNum: "층수",
            bind_dialogTitle: "장소 지도 위치 설정",
            bind_ruleSite: "장소를 선택하십시오",
            bind_ruleSiteLength: "문자수는 5 ~ 50자 사이",
            bind_pointName: "지점 이름을 입력하십시오.",
            bind_pointLength: "문자수는 1 ~ 20자 사이",
            bind_pointAddr: "지점 주소를 입력하십시오.",
            bind_pointaddrLength: "문자수는 1 ~ 100자 사이",
            bind_ruleMap: "지도 설정 위치를 입력하십시오",
            bind_messageSucc: "바인딩 성공!",
            bind_messageLose: "바인딩 실패!",
            bind_foundSucc: "생성 성공!",
            bind_foundLose: "생성 실패!",

            // 경보 규칙
            alarmRule_OpenState: "开켜짐 상태",
            alarmRule_isOpen: "켜시겠습니까",
            alarmRule_event: "사건 유형",
            alarmRule_productRank: "제품 레벨",
            alarmRule_entityRank: "회사 레벨",
            alarmRule_siteRank: "장소 레벨",
            alarmRule_deviceRank: "설비 레벨",
            alarmRule_openState: "경보 규칙 켜짐 상태를 수정하시겠습니까?",
            alarmRule_drawerTitle: "관련 사용자",
            alarmRule_sysUser: "시스템 사용자",
            alarmRule_TemUser: "임시 사용자",

            // 연동 설비
            linkRule_linkDeviceCode: "연동 설비 코드를 입력하십시오.",
            linkRule_linkDevice: "연동 설비",
            linkRule_triggerDevice: "설비 트리거",
            linkRule_triggerEvent: "트리거 사건",
            linkRule_linkeOrder: "연동 지령",
            linkRule_allState: "전부 상태",

            // 전송 상황
            push_aliPhoneRecords: "클라우드 전화 기록",
            push_linePhoneRecords: "전용라인 전화 기록",
            push_aliSmSRecords: "클라우드 메세지 기록",
            push_appPush: "APP 전송 기록",
            push_gzhPush: "공중호 전송 기록",

            // 바인딩 인원
            bindUser_Title: "바인딩 인원",
            bindUser_userName: "사용자 이름",
            bindUser_isFirstBind: "첫 번째 바인딩 여부",
            bindUser_firstBind: "첫 번째 바인딩",
            bindUser_ISControls: "작업 권한이 있는지 여부",
            bindUser_canControls: "작업 가능",
            bindUser_noCOntrols: "작업 불 가능",
            bindUser_Tips: "작업 권한을 수정하시겠습니까?",
            bindUser_editRole: "권한 수정",

            basice_DetectorData: "탐지기 데이터",
            basice_DetectorNumber: "탐지기 번호",
            basice_SensorType: "센서 유형",
            basice_report: "보고 주기",
            basice_4gGas: "4g 산업용 가스 유형",
            basice_wireLess: "무선 모듈 유형",
            edition: "버전",

            device_allNnum: "총 설비 수",
            OwnDevice: "설비 있음",
            NoDevice: "설비 없음",
            siteImg: "장소 사진",
            pointIMg: "지점 사진",
            NoWonerInfo: "소유자정보 없음",
            selectAll: "모두 선택",
            NoSelectAll: "모두 선택 해제",
        },

        // 사용자
        userManage: {
            // 사용자 기본 정보 표시
            details_edit: "수정",
            details_userNewsTitle: "사용자 정보",
            details_saveEdit: "수정 사항 저장",
            details_editPawword: "암호 수정",
            details_loginName: "계정",
            details_name: "이름",
            details_phone: "연락 전화",
            details_sex: "성별",
            details_roleID: "역할",
            details_Entity: "소속 회사",
            details_limitsName: "권한",
            details_createTime: "생성 시간",
            details_upDateTime: "업데이트 시간",
            details_WeChatOfficialID: "위챗 공중호 ID",
            details_WechatID: "위챗 ID",
            details_isLogin: "계정 로그인 여부",
            details_Mna: "남",
            details_woman: "여",
            delUser: "이 사용자를 삭제할지 여부입니다",

            // 사용자 스위치 정보
            details_userSwitchTitle: "사용자 스위치 정보",
            details_isLoginApp: "App 로그인읗 허용 하시겠습니까",
            details_isReceivePush: "APP 측 경보를 수신 하시겠습니까",
            details_isLoginPc: "PC 로그인을 허용 하시겠습니까",
            details_isReceiveSMS: "메세지 수신 하시겠습니까",
            details_isREceivePhon: "전화를 수신 하시겠습니까",
            details_isReceiveOfficial: "공중호를 수신 하시겠습니까",
            details_isPcAlarmPush: "PC측 경보를 수신 하시겠습니까",
            details_isLoginProgram: "애플릿 로그인을 허용 하시겠습니까",
            details_appPush: "APP 측 경보를 수신 하시겠습니까",
            details_subscribe_GZH: "공중호를 구독 하시겠습니까",
            details_gzhSubscribe: "공중호 전송을 수신 하시겠습니까",
            details_query_lower: "집계 수자에 하위 항목이 포함되어 있습니까",
            details_no_query: "하위를 조회하지 않음 ",
            details_query: "하위를 조회함",
            time_interval: "시간 간격(단위:시간)",
            time_hourly: "시간",
            // 집계 수자에 하위 항목이 포함되어 있습니까
            // 집계 수자에 하위 항목이 포함되어 있습니까
            // 사용자 정보, 입력하지 않은 경우 알림
            details_loginNamePla: "계정을 입력하십시오",
            details_namePla: "이름을 입력하십시오",
            details_phonePla: "연락 전화 번호를 입력하십시오",
            details_sexPla: "성별을 선택하십시오",
            details_roleIDPla: "역할을 선택하십시오",
            details_EntityPla: "소속 회사를 선택하십시오",
            details_limitsNamePla: "권한 이름을 입력하십시오",
            details_WeChatOfficialIDPla: "위챗 공중호 ID를 입력하십시오.",
            details_WechatIDPla: "위챗 ID를 입력하십시오",
            details_isLoginPla: "계정 로그인 여부를 선택하십시오.",

            // 사용자 정보 검증 알림
            details_loginNameRule: "계정은 비워둘 수 없습니다",

            details_nameRule: "이름은 비워둘 수 없습니다",
            details_phoneRule: "연락 전화번호는 비워둘 수 없습니다",
            details_phoneRuleTwo: "정확한 전화번호를 입력하십시오.",
            details_roleNoSp: "역할은 비워둘 수 없습니다",
            details_sexRule: "성별은 비워둘 수 없습니다",
            details_pawword: "비밀번호는 비워둘 수 없습니다.",
            details_pawwordTwo: "암호는 문자와 숫자로 구성되어야 합니다.",
            details_login_Name: "계정은 비워둘 수 없습니다",
            details_login_name_two: "계정은 문자와 숫자로 구성되어야 합니다.",
            details_pawwordThree: "암호는 6-18자리 문자여야 합니다.",
            details_pawwordFour: "암호를 입력하십시오.",
            details_pawwordfive: "암호를 다시 입력하십시오",
            details_pawwordsix: "두 번 입력한 암호가 일치하지 않습니다!",
            details_EntityRule: "소속 단위는 비워둘 수 없습니다.",
            details_isLoginRule: "계정 로그인 여부는 비워둘 수 없습니다.",

            details_gzhPushRule: "공중호 전달 계획",


            // 사용자 스위치 정보, 입력하지 않은 경우 알림
            details_isLoginAppPla: "App 로그인 허용 여부를 선택하십시오.",
            details_isReceivePushPla: "PC측 경보수신 여부를 선택하십시오.",
            details_isLoginPcPla: "PC 로그인 허용 여부를 선택하십시오.",
            details_isReceiveSMSPla: "메세지 수신 여부를 선택하십시오.",
            details_isREceivePhonPla: "전화 수신 여부를 선택하십시오.",
            details_isReceiveOfficialPla: "공중호 전달 수신 여부를 선택하십시오.",
            details_isPcAlarmPushPla: "PC측 경보 수신 여부를 선택하십시오.",
            details_isLoginProgramPla: "애플릿 로그인 허용 여부를 선택하십시오.",

            // 사용자 스위치 정보, 확인 알림
            details_isLoginAppRule: "App 로그인 허용 여부는 비워둘 수 없습니다.",
            details_isReceivePushRule: "APP 경보 전달 수신 여부는 비워둘 수 없습니다.",
            details_isLoginPcRule: "PC 로그인 허용 여부는 비워둘수 없습니다",
            details_isReceiveSMSRule: "메세지 수신 여부는 비워둘 수 없습니다.",
            details_isREceivePhonRule: "전화 수신 여부는 비워둘 수 없습니다.",
            details_isReceiveOfficialRule: "공중호 수신 여부는 비워둘 수 없습니다.",
            details_isPcAlarmPushRule: "PC측 경고 수신 여부는 비워둘 수 없습니다.",
            details_isLoginProgramRule: "애플릿 로그인 허용 여부는 비워둘 수 없습니다.",

            details_confimEdit: "수정 확인",

            user_roleID: "역할 ID",
            user_rolePla: "역할 검색",
            user_sexSearPla: "성별 검색",
            user_moreSear: "더 많은 검색",
            user_entityNamePla: "회사 이름을 입력하십시오.",
            user_confirmPaword: "암호 확인",
            user_addUser: "신규 사용자",
            user_cipher: "암호",
            user_submit: "제출",
            user_role: "역할",
            // user_
            // user_
            // user_

        },

        // 경보 접수 플랫폼
        alarmPlatform: {

            warchMan: "당직자",
            connectState: "연결 상태",
            succeed: "성공",
            lose: "실패",
            deviceAllData: "총 설비 수",
            todayAlarm: "오늘 경보 횟수",
            pushcondition: "전달 상태",
            alarmHandleHandrail: "경보 처리 란",
            deviceBasiceNews: "설비 기본 정보",
            deviceData: "설비 데이터",
            alarmReason: "경보 원인",
            alarmReasonPla: "경보 원인을 선택하십시오.",
            remark: "비고",
            handleAlarm: "경보 처리",
            rest: "재 설정",
            leisure: "유휴",
            alarmRecords: "경보 기록",
            video: "비디오",

            table_deviceNumber: "설비 번호",
            table_alarmTime: "경보 시간",
            table_address: "설치 위치",
            deviceModelNum: "설비 모델",
            deviceType: "설비 유형",
            entity: "소속 회사",


            handle: "처리",
            untreatedNews: "미 처리 정보",
            dutyLog: "당직 일지",
            close: "끄기",

            planeImg: "명면도",
            TwoDMap: "2D 지도",
            threeDMap: "3D 지도",
            threeDModeling: "3D 모형",
            videoWatch: "비디오 모니터링",
            relevanceVideo: '설비-비디오 연결',
            dragCom: '길게 누르고 당겨오기',

            timePushRecords: "현재 전송 기록",
            AliSmS: "클라우드 메세지",
            AliVoice: "클라우드 음성",
            PruvateTelephone: "전용라인 전화",
            appPush: "APP 전송",

            Mess_selectAlarm: "경보 사건을 먼저 선택하십시오!",
            mess_handleAlarmLose: "경보 처리 실패!",
            mess_handleAlarmSuccess: "경보 처리 성공!",
            mess_handleNewAlarm: "현재 경보를 우선 처리하십시오!",
            mess_noVideo: "카메라 설비, 단일 비연관 장치!",
            rulesHandleWay: "처리 방식을 선택하십시오.",
            rulesRemark: "비고를 입력하십시오",

            selectKey: "버튼 테스트",
            selectSmoke: "연기 테스트",
            selectGas: "가스 테스트",
            selectReharsal: "연습",
            selectFireHazard: "화재",
            selectOther: "기타",
            selectAll: "전부",
            selectUnconfirmed: "미 확인",

            // 경보 기록
            alarmRecord_btn: "설비 경보 기록으로 돌아가기",
            alarmRecord_searSiteName: "장소 이름을 입력하십시오.",
            alarmRecord_SearTypeName: "유형 이름을 입력하십시오.",
            alarmRecord_alarmAllNum: "총 경보 수",
            alarmRecord_todayAlarm: "오늘 경보",
            alarmRecord_processor: "처리인",
            alarmRecord_handleTime: "처리 시간",
            alarmRecord_handlingSuggestion: "처리 의견",
            alarmRecord_histouricalRecord: "히스토리",
            alarmRecord_handleTitle: "경보 처리",
            alarmRecord_selectPla: "경보 원인을 선택하십시오.",
            alarmRecord_alarmReason: "경보 비고를 입력하십시오.",
            alarmRecord_handlecomplete: "처리 완료!",
            alarmRecord_handleLose: "처리 실패!",
            alarmRecord_check_TypeMessage: "경보 원인을 선택하십시오.",
            alarmRecord_alarmRemarks: "경보 비고를 입력하십시오.",

            // 당직 일지
            dutyLog_add: "신규 ",
            dutyLog_FillMan: "작성자",
            dutyLog_FillContent: "작성 내용",
            dutyLog_attachmentsOne: "첨부1",
            dutyLog_attachmentsTwo: "첨부2",
            dutyLog_attachmentsThree: "첨부3",
            dutyLog_download: "다운로드",
            dutyLog_empty: "비어 있음",
            dutyLog_DialogTitle: "신규 일지",
            dutyLog_addFormLabel: "작성 내용",
            dutyLog_addFormPla: "작성 내용을 입력하십시오.",
            dutyLog_addFormRule: "작성 내용은 비워둘 수 없습니다.",

            message_alarmLose: "재생 주소 가져오기 실패!",
            message_alarmLoseAndGuanlian: "관련 카메라 재생 주소 가져오기 실패!",
            message_alarmLoseNOvide: "재생 주소 가져오기 실패!연관된 카메라가 없습니다.",
            message_plangImgLose: "이 경보에는 평면도가 없습니다.",
            message_noRelevanc: "관련 없는 카메라",
            message_alarmBlockleisure: "경보 접수란이 비어 있습니다.조회 할 수 없습니다",
            // message_noRelevanc:"관련 없는 카메라",

        },

        // 전송 기록
        pushRecords: {
            public_time: "호출 시간",
            public_pushMeassger: "전송 시간",
            public_deviceNum: "장치 통합 번호",

            AliSmsPhone: "휴대폰 번호",
            AliSmsTime: "발생 시간",
            AliSmsEventType: "사건 유형",
            AliSmsEvent_alarm: "경보 사건",
            AliSmsEvent_fault: "고장 사건",
            AliSmsEvent_heartbeat: "심장 박동 사건",
            AliSmsEvent_deviceOffLine: "설비 오프라인 사건",
            AliSmsEvent_deviceOnLine: "설비 온라인 사건",
            AliSms_newMessage: "메세지 내용",

            // 음성 전송
            AliVoiceTime: "호출 시간",
            AliVoicePush: "전송 내용",

            // 공중호
            publicAccount_template: "템플릿 ID",
            publicAccount_pushUser: "사용자 ID 전송",
            publicAccount_errmsg: "결과 설명",
            publicAccount_msgid: "메세지 ID",
            publicAccount_deviceNum: "설비 통합 번호",
            publicAccount_ruleId: "규칙 ID",

            // 전용라인
            specialLine_answerState: "전화 수신 상황",
            specialLine_userNoAnswer: "사용자가 받지 않음",
            specialLine_refuseAnswer: "사용자가 수신 거부",
            specialLine_answerButFinish: "사용자가 수신 후 주동 적으로 끊음",
            specialLine_answerButPassivityFinish: "사용자가 수신 후 피동 적으로 끊김",
            specialLine_answerTIme: "전화 수신 시간",
            specialLine_noAnswer: "받지 않음",
            specialLine_overTime: "종료 시간",

            // app전송
            appPush_pushResult: "결과 전송",
            appPush_pushRState: "상태 전송",

            // 클라우드 전화 기록
            aliPho_phoneSearPla: "전화 입력하여 검색",
            aliPho_rulesPhone: "11자리 전화 번호를 입력하십시오.",

            "user_mame": "사용자 이름",
            "push_Result": "결과 전송",
            "Push_Descriptive": "설명 전송 하기",
            "Event_Name": "사건 이름",
            "push_time": "전송 시간",

            push_success: "전송 성공",
            push_limit: "이 템플릿은 이미 하달 상한에 도달했습니다",
            push_content_not_issue: "메세지 내용 하달은 지원되지 않습니다.",
            push_content_abnormal: "하달 내용이 규범적이지 않습니다.",
            push_account_maximum_issue: "이 계정은 이미 하달 상한에 도달하여 호출이 제한되었습니다.",
            push_template_under_review: "이 템플릿에서 보낸 내용은 현재 심사 중 입니다.",
            push_Poorly_formatted_fields: "필드 형식이 규범적이지 않습니다.",
            push_Public_Exception: "공중호 전송 이상",
        },

        // 사건 유형
        eventType: {
            eventTitle: "사건 유형",
            eventIsOpen: "사건 스위치",
            alarmEvent: "경보 사건",
            faultEvent: "고장 사건",
            heartbeatEvent: "심장 박동 사건",
            OffLineEvent: "설비 오프라인 사건",
            OnLineEvent: "설비 온라인 사건",
        },

        // 설비 관리-고장 기록
        faultRecords: {
            backFaultRecords: "설비 고장 기록으로 돌아가기",
            handleStatePla: "처리 상태를 선택하십시오.",
            handleDeviceState: "설비 상태를 선택하십시오.",
            historicalRecord: "히스토리",
            handle: "이미 처리됨",
            noHandle: "처리 되지 않음"
        },

        // 설비 관리-경보 기록
        alarmRecirds: {
            backAlarmRecords: "설비 고장 기록으로 돌아가기",
            selectAlarmPla: "경보 원인을 선택하십시오.",
            pushState: "전송 상태",
            dialogTitle: "경보 처리",
            alarmCausePla: "경보 원인을 선택하십시오.",
            AllAlarmCause: "전부 경보 원인",
            pushRecords: "전송 기록",
            isCollectTips: "설비 수집을 취소하시겠습니까?",
            cancelCollect: "수집 취소",
            message_cancelCollectSucc: "수집 취소 성공!",
            message_cancelCollectLose: "수집 취소 실패!",
        },

        // 설치 관리-설치점 관리
        installPoint: {
            pointName: "지점 이름",
            pointNamePla: "지점 이름을 입력하십시오.",
            deviceCoding: "설비 번호",
            deviceCodingPla: "설비 번호를 입력하여 검색하십시오.",
            pointAddrPla: "지점 주소를 입력하여 검색하십시오.",
            installNews: "설치 지점 정보",
            saveEdit: "수정 사항 저장",
            verifyEdit: "수정 확인",
            collectPointTips: "설치 지점을 저장하시겠습니까?",
            colloect: "수집",
            drawerTitle: "설치 지점 상세 정보",
            dialog: "신규 설치 지점",
            rules_noSpecial: "특수 문자를 포함할 수 없습니다!",

            form_deviceNum: "설비 번호:",
            form_deviceNumPla: "설비 번호를 입력하십시오",
            form_floorNum: "층수:",
            form_floorNumPla: "층수를 입력하십시오",
            form_pointAddrPla: "지점 주소를 입력하십시오.",
            form_entity: "소속 회사:",
            form_pointAddr: "지점 주소:",
            form_siteName: "장소 이름:",
            form_PointName: "지점 이름:",
            form_entityPla: "소속 회사을 선택하십시오",
            form_naem: "이름",
            message_succ: "설치 지점 수집 성공!",
            message_lose: "설치 지점 수집 실패!",
            delUnit: "이 장치를 삭제하시겠습니까?   ",

            // form_

            img_title: "설치 지점 사진 정보",
            img_deleteTips: "이 사진을 삭제하시겠습니까?",
            img_messageSucc: "사진 업로드 성공!",
            img_messageLose: "사진 업로드 실패!",
            img_messageCancel: "업로드 취소됨",
            img_tips: "현재 지점을 확인하거나 취소하십시오!",
            img_pointSucc: "지점 저장 성공!",
            img_pointLose: "지점 저장 실패!",
            point_delete_tips: "지점을 삭제 하면 연결된 설비가 바인딩되지 않은 상태로 복원 되며 지점 사진 등 관련 데이터도 삭제됩니다. 지점을 삭제하시겠습니까?",
        },

        // 설치 관리 - 장소 관리
        siteManage: {
            search_site: "장소 이름 검색",
            search_siteAddr: "장소 주소 검색",
            addBtn: "신규",
            site_collectTips: "이 장소를 수집 하시겠습니까?",
            site_drawerTitle: "장소 상세 정보",
            site_news: "장소 정보",
            site_contactPerson: "연락처:",
            site_contactPersonPhone: "연락처 전화:",
            site_log: "경도:",
            site_lng: "위도:",
            site_contactPersonName: "연락처 이름:",
            site_contactPersonNamePla: "연락처 이름을 입력하십시오.",
            site_contactPersonNum: "연락처 전화",
            site_contactPersonNumPla: "연락처 전화를 입력 하십시오",
            site_imgNews: "사진 정보",
            site_contactPersonPla: "연락처를 입력하십시오",
            site_contactPersonPhonePla: "연락처 전화를 입력 하십시오",
            site_siteAddr: "장소 주소",
            site_logPla: "경도를 입력하십시오",
            site_lngPla: "위도를 입력하십시오",
            site_ruleSiteName: "장소 이름은 비워둘 수 없습니다.",
            site_ruleSiteAddr: "장소 주소는 비워둘 수 없습니다.",
            site_ruleContactName: "연락처 이름은 비워둘 수 없습니다",
            site_rulePhone: "연락처 전화는 비워둘 수 없습니다",
            site_rulePhoneTwo: "신규 데이터 실패!전화 격식에 주의하십시오!",
            site_modelID: "모델 ID",
            site_modelNnme: "모델 이름",
            site_modelCreateTime: "생성 시간",
            site_modelNamePla: "모델 이름을 입력하십시오.",
            site_collectSucc: "장소 수집 성공!",
            site_collectLose: "장소 수집 실패!",
            site_editSucc: "지점 수정 성공!",
            site_editLose: "지점 수정 실패!",
            site_cancelAddSite: "새 장소가 취소되었습니다!",
            site_delete_tips: "장소를 삭제하면 장소 아래의 지점과 층도 동시에 삭제되고 연결된 설비도 바인딩되지 않은 상태로 복원됩니다. 장소를 삭제하시겠습니까?",
        },

        // 설치관리_층관리
        floorManage: {
            floor_collectTips: "이 층을 수집하시겠습니까?",
            floor_drawer: "층 상세 정보",
            floor_news: "층 정보",
            floor_dialogTItle: "신규 층",
            floor_createTIme: "생성 시간",
            floor_floor: "층수",
            floor_floorPla: "층수를 입력하십시오",
            floor_messageSucc: "층 수집 성공!",
            floor_messageLose: "층 수집 실패!",
            floor_floorNum: "건물",

            add_floor: "층",
            add_site: "소속 장소",
            add_reset: "재 설정",
            add_rulerNum: "숫자 값을 입력하십시오.",
            add_flooRules: "층은 비워둘 수 없습니다",
            add_siteRules: "소속 장소는 비워둘 수 없습니다",

            img_addSucc: "사진 업로드 성공!",
            img_addLose: "사진 업로드 실패!",
            floor_delete_tips: "층을 삭제하시겠습니까?",
        },

        // 수집 - 층 수집
        collectFloorManage: {
            floor_numPla: "층수 검색",
            floor_cancelCollectTips: "이 층의 수집을 취소하시겠습니까?",
            floor_uncollect: "수집 취소",
            floor_dialogTitle: "신규 설비 상세 정보",
            floor_createdTimePla: "생성 시간을 입력하십시오.",
            floot_floorNum: "",
            floor_siteNamePla: "장소 이름을 선택하십시오.",
            floor_siteAddrPla: "장소 주소",
            message_uncollectSucc: "층 수집 취소 성공!",
            message_uncollectLose: "층 수집 취소 실패!",
            message_floorNoSpeac: "층은 비워둘 수 없습니다!"
        },

        // 수집 - 설치지점 수집
        collectPoint: {
            point_searchName: "지점 이름 검색",
            point_collectTips: "지점 수집을 취소하시겠습니까?",
            point_dialogTitle: "신규 설치 지점",
            point_relevanceSite: "관련 장소",
            point_uncollectSucc: "설치 지점 제거 성공!",
            point_uncollectLose: "설치 지점 제거 실패!",
        },

        // 수집 - 장소 수집
        collectSite: {
            site_collectTips: "장소 수집을 취소",
            site_cancelCollect: "수집 취소",
            site_rulesLon: "경도는 비워둘 수 없습니다",
            site_rulelat: "위도는 비워둘 수 없습니다",
            stie_cancelSiteSucc: " 장소 수집 취소 성공!",
            site_cancelSiteLose: "장소 수집 취소 실패!",

            basic_deleteImgTips: "이 사진을 삭제하시겠습니까?",
            basic_UpImgSucc: "사진 업로드 성공!",
            basic_UpImgLose: "사진 업로드 실패!",
            basic_cancelUp: "업로드가 취소되었습니다!",

            map_TiTle: "지도 정보",
            map_editPoint: "지점 수정",
            map_confirmPoint: "저장",
            map_lon: "경도",
            map_lng: "위도",
            map_siteNamePla: "조회할 장소 이름을 입력하십시오.",
            map_tips: "파란색 물방울을 원하는 위치로 이동할 수 있습니다.",
        },

        // 생산 관리 - 설비 모형
        deviceProfile: {
            profile_modelName: "모형 이름",
            profile_modelNamePla: "모형 이름을 입력하십시오.",
            profile_enttity: "회사 이름",
            profile_entityPla: "회사 이름을 입력하십시오.",
            profile_tableModelName: "모형 이름",
            profile_drawerTitle: "설비 모형 상세 정보",
            profile_basiceLabel: "기본 정보",
            profile_dialogTitle: "신규 설비 모형",

            addForm_IDPla: "ID를 입력하십시오",
            addForm_appkeyPla: "aep_appkey 를 입력하십시오.",
            addForm_appsecretPla: "aep_appsecret을 입력하십시오.",
            addForm_product_idPla: "product_id를 입력하십시오.",
            addForm_command_idPla: "command_id를 입력하십시오.",
            addForm_masterkey: "masterkey를 입력하십시오",
            addForm_protocol_type: "protocol_type을 입력하십시오.",
            addForm_protocol_typeRule: "protocol_type은 비워둘 수 없습니다",

            addForm_expire_time: "지령 캐시(Cache) 시간",
            addForm_expire_timePla: "지령 캐시(Cache) 시간을 입력하십시오.",
            addForm_onenet_apikeyPla: "onenet_apikey를 입력하십시오.",
            addForm_BC260Y: "BC260Y 설비",
            addForm_BC260YPla: "BC260Y 설비를 입력하십시오.",
            addForm_Platform: "운영사 플랫폼",
            addForm_PlatformPla: "운영사 플랫폼을 입력하십시오.",
            addForm_creator: "작성자",
            addForm_creatorPla: "작성자 이름을 입력하십시오",
            addForm_onenerPla: "통신사onener 를 입력 하십시오 ",

            rules_modelNameNoSpace: "모형 이름은 비워둘 수 없습니다",
            rules_modelNameLength: "길이는 20자를 초과할 수 없습니다",
            rules_maxLength: "길이는 50자를 초과할 수 없습니다",
            rules_entityNoSpace: "소속 회사는 비워둘 수 없습니다",
            rules_platform: "운영사 플랫폼은 비워둘 수 없습니다",

            message_entity: "회사는 비워둘 수 없습니다!",
            message_addDeviceSucc: "신규 설비 모형 성공!",
            message_addDeviceLose: "신규 설비 모형 실패!",


        },

        // 생산 관리 - 설비 모델
        deviceModel: {
            modelName: "모델 이름",
            model_NamePla: "모델 이름을 입력하십시오.",
            table_modelName: "모델 이름",
            table_model: "소속 모델",
            model_drawer: "설비 모델 상세 정보",
            model_dialog: "신규 설비 모델",
            rules_modelNoVacancy: "설비 모델은 비워둘 수 없습니다",
            form_deviceID: "설비 모델 ID",
            form_deviceIDPla: "설비 모델 ID를 입력하십시오.",
            form_modelCodingPla: "모델 코드를 입력하십시오.",
            form_modelIntro: "모델 소개 ",
            form_modelIntroPla: "모델 소개 입력",
            form_deviceModel: "설비 모델",
            form_LowVoltage: "최저 전압",
            form_LowVoltagePla: "최저 전압을 입력하십시오.",
            form_LowVoltageNum: "저전압 횟수",
            form_LowVoltageNumPla: "저전압 횟수를 입력하십시오.",
            form_lowSignal: "최소 신호값",
            form_lowSignalPla: "최소 신호값을 입력하십시오.",
            form_SiganWeakNum: "신호 불량 횟수",
            form_SiganWeakNumPla: "신호가 약한 횟수를 입력하십시오.",
            form_deviceMoelPlaNo: "설비 모델을 입력하십시오.",
            ruler_modelName: "모델 이름은 비워둘 수 없습니다.",
            ruler_modelLength: "길이 1~20자",
            ruler_modelCoding: "모델 코드는 비워둘 수 없습니다!",
            ruler_modelIntro: "모델 소개는 비워둘 수 없습니다!",
            ruler_devcieModel: "설비 모델은 비워둘 수 없습니다!",
            message_addModelSucc: "신규 모델 성공!",
            message_addModelLose: "신규 모델 실패!",
            modelImg: "모델 사진",
            useManual: "사용 설명서",
            addUseManual: "사용 설명서 추가",
            delTips: "삭제하기 전에 삭제할 데이터를 체크하고 삭제할 데이터를 확인하십시오.",
            dels: "일괄 삭제",
            confirmDelUM: "이 사용 설명서를 삭제하시겠습니까?",
            file: "파일",
            fileDawnload: "파일 다운로드",
            fileName: "파일 이름",
            uoloadFile: "파일 업로드",
            file10MB: "파일 크기는 10MB를 초과할 수 없습니다.",
            fileSizeIS: "파일 크기는 현재 파일 크기로 10mb를 초과할 수 없습니다",
        },

        // 생산 관리 - 모델 지령
        modelCommand: {
            modelNum_orderName: "지령 이름",
            modelNum_orderNamePla: "지령 이름을 입력하십시오.",
            modelNum_modelNum: "소속 모델",
            modelNum_editOrderTitle: "모델 수정 지령",
            modelNum_addOrderTitle: "신규 모델 지령",
            modelNum_orderArgument: "지령 파라메터",
            modelNum_copy: "복사",
            modelNum_copySucc: "복제 성공",
            modelNum_JsonText: "템플릿 파라메터 편집",
            modelNum_modelPla: "모델 이름을 입력하십시오.",
            modelNum_ruleOrder: "지령 이름은 비워둘 수 없습니다!",
            modelNum_ruleModelNum: "소속 모델은 비워둘 수 없습니다",
            modelNum_orderArgumentPla: "지령 파라메터는 비워둘 수 없습니다.",
            type_drawerTitle: "설비 유형 상세 정보",
            message_addOrderSucc: "신규 지령 성공!",
            message_addOrderLose: "신규 지령 실패!",
            message_editOrderSucc: "지령 수정 성공!",
            message_editOrderLose: "지령 수정 실패!",
        },

        // 생산 관리 - 설비 유형
        deviceType: {
            type_name: "유형 이름",
            type_namePla: "유형 이름을 입력하십시오",
            type_coding: "유형 번호",
            type_codingPla: "유형 번호를 입력하십시오.",
            type_drawerTitle: "설비 유형 상세 정보",
            type_dialogTitle: "신규 설비 유형",
            type_id: "유형 ID",
        },

        // 생산 관리 - 설비 품질 검사
        deviceCheck: {
            check_checkMid: "품질 검사 중",
            check_needCheck: "품질 검사가 필요한 장치",
            check_needCheckPla: "장치 imei를 입력하십시오...",
            check_lowSignal: "최소 신호 강도",
            check_lowVoltage: "최소 전압(mv)",
            check_checkDayHearbeat: "24시간 심장 박동 검사 여부",
            check_qualityTesting: "품질 검사 데이터 확인 여부",
            check_alarmData: "경보 데이터 확인 여부",
            check_normalDevice: "정상 설비",
            check_importClient: "고객 가져오기",
            check_clearTableTips: "정상 테이블을 비우시겠습니까?",
            check_clearTable: "테이블 비우기",
            check_abnormalDevice: "이상 설비",
            check_abnormalNews: "이상 정보",
            check_selectDevcieType: "설비 유형 선택",
            check_deviceFormatLose: "설비 격식이 정확하지 않습니다!",
            check_qualitySucc: "품질 검사 완료!",
            check_qualityLose: "품질 검사 실패!",
            messge_qualitySucc: "설비는 품질 검사 완료 되었습니다!",
            check_desc_uncheck: "검사하지 않음",
            check_desc_success: "정상",
            check_desc_error: "이상",
        },

        // 생산관리_품질검사기록
        checkRecord: {
            record_result: "전부 품질 검사 결과",
            record_normal: "정상",
            record_abnormal: "이상",
            record_checkLog: "검사 일지",
            record_checkTime: "품질 검사 시간",
            record_checkConditions: "품질 검사 상세 정보",
            record_qualityNormal: "품질 검사 정상",
            record_qualityAbnormal: "품질 검사 이상"
        },

        // 생산 관리_설비 가져오기 :{
        deviceImport: {
            import_plarform: "플랫폼",
            import_plarformPla: "플랫폼 선택 검색",
            import_type: "가져오기 유형",
            import_import: "가져오기",
            import_signOut: "로그아웃",
            import_importEntity: "회사 가져오기",
            import_singIngPlafrom: "플랫폼 등록",
            import_Time: "가져오기 시간",
            import_Log: "가져오기 일지",
            import_state: "가져오기 상황",
            import_download: "첨부 파일 다운로드",
            import_dialogTitle: "설비 가져오기",
            import_singIngPlafromPla: "등록 플랫폼을 선택하십시오.",
            import_expireTime: "만료 시간",
            import_selectTime: "날짜 선택",
            import_drawerTitle: "설비 가져오기 상세 정보",
            import_deviceState: "설비 가져오기 상황",
            import_signInResult: "등록 결과",
            import_successful: "성공",
            import_lose: "실패",
            import_NosignInLog: "등록 일지",

            import_signInLog: "등록 일지",
            import_signInTime: "등록 시간",
            import_allType: "전부 가져오기 유형",
            import_deviceImport: "설비 가져오기",
            import_deviceSignOut: "설비 로그아웃",
            import_importSucc: "가져오기 성공!",
            import_importLose: "가져오기 실패!",

            message_devcieModelNum: "설비 모델은 비워둘 수 없습니다!",
            message_signInPlarform: "등록 플랫폼은 비워둘 수 없습니다!",
            message_importEntity: "가져온 회사는 비워둘 수 없습니다!",
            message_devcieType: "설비 유형은 비워둘 수 없습니다!",
            message_importNo: "가져온 파일은 비워둘 수 없습니다!",
            // message_
            import_file_type: " .xlsx 파일만 업로드 가능 합니다."
        },

        //  생산 관리 - 등록 기록
        registerRecord: {
            register_platformPla: "등록 플랫폼을 선택하십시오.",
            register_allResult: "전부 등록 결과",
            register_registerSucc: "등록 성공",
            register_registerLose: "등록 실패",
        },

        // 생산 관리 - 로그아웃 기록
        logOutRecord: {
            logOut_platform: "플랫폼 로그아웃",
            logOut_result: "로그아웃 결과",
            logOut_resultLog: "로그아웃 일지",

            logOut_time: "로그아웃 시간"
        },

        // 경보 관리 - 경보 규칙
        alarmRule: {
            alarm_radius: "범위 레벨",
            alarm_opentState: "열기 상태",
            alarm_searPla: "설비 번호/회사 이름을 입력하여 검색하십시오.",
            alarm_deleteTips: "이 경보 규칙을 삭제하시겠습니까?",
            alarm_delete: "삭제",
            alarm_drawerTitle: "신규 경보 규칙",

            add_titleOne: "규칙 작성",
            add_titlePlaOne: "경보 범위 선택, 경보 대상 설정",
            add_titleTwo: "경보 접수인원 추가",
            add_titlePlaTwo: "관련 규칙을 추가하는 시스템 사용자",
            add_titleThreePla: "관련 규칙을 추가하는 임시 사용자",
            add_open: "열기",
            add_alarmObject: "경보 대상",
            add_previous: "이전",
            add_next: "다음",
            add_deviceID: "설비ID",
            add_deviceName: "설비 이름",
            add_deviceOther: "기타 정보",
            add_devicePla: "설비 번호를 입력하십시오.",
            add_eventTypeNoEmpty: "사건 유형은 비워둘 수 없습니다.",
            add_selectAlarm: "경보 대상을 선택 하십시오",
            add_createrAlarmSucc: "경보 규칙 생성 성공!",
            add_createrAlarmLose: "경보 규칙 생성 실패!",
            add_entityRank: "회사 레벨",
            add_deviceRank: "설비 레벨",

            sys_title: "시스템 사용자 리스트",
            sys_searName: "이름 검색",
            sys_phoneName: "휴대폰 번호 검색",
            sys_searBtn: "검색",
            sys_clearSear: "조건 비우기",
            sys_name: "이름",
            sys_phone: "연락전화",
            sys_role: "역할",
            sys_addTips: "사용자를 경보 규칙에 추가하시겠습니까?",
            sys_add: "추가",
            sys_cardTitle: "연관된 규칙의 시스템 사용자",
            sys_RemoveTips: "사용자를 경보 규칙에서 제거하시겠습니까?",
            sys_removerBtn: "제거",
            sys_phoneFormat: "11자리 올바른 휴대폰 형식을 입력하십시오.",
            sys_addSucc: "추가 성공!",
            sys_addLose: "추가 실패!",
            sys_romoverSucc: "제거 성공!",
            sys_removerLose: "제거 실패!",

            tem_title: "임시 사용자 리스트",
            tem_receivePhone: "전화 수신 하시겠습니까",
            tem_receiveSms: "메세지 수신 하시겠습니까",
            tem_cardTitle: "연관된 규칙의 임시 사용자",
        },

        // 경보 규칙 - 연동 규칙
        linkRules: {
            link_trigger: "트리거 설비",
            link_triggerPla: "트리거 설비 번호를 입력하십시오.",
            link_linkage: "연동 설비",
            link_editLinkageTips: "설비 연동 규칙 켜기 상태를 수정하시겠습니까?",
            link_dialogTitel: "설비 연동 규칙",

            message_openDetailsLose: "상세 정보 열기 실패",
            message_delectSucc: "삭제 성공!",
            message_delectLose: "삭제 실패!",

            add_triggerCondition: "트리거 조건",
            add_triggerConditionTips: "트리거 조건을 비우시겠습니까?",
            add_deviceCoding: "비 번호",
            add_eventType: "사건 유형",
            add_isOpen: "열겠습니까",
            add_clearLinkageTips: "연동 장치를 비우시겠습니까?",
            add_executeMOvement: "실행 작업",
            add_executeMOvementTips: "실행 작업을 비우시겠습니까?",
            add_devcieOrder: "설비 지령",
            add_saveLink: "규칙 저장",
            add_setTrigger: "트리거 조건 설정",
            add_setLinkage: "연동 설비 설정",
            add_triggerEvent: "트리거 사건",
            add_select: "선택",
            add_linakageCommand: "장치 연동 명령",
            add_deviceModelNum: "설비 모델",

            message_selectTrigger: "트리거할 설비를 선택하십시오!",
            message_triggerEvent: "트리거할 사건 유형을 선택하십시오!",
            message_lingkaeDevice: "연동 장치를 선택하십시오!",
            message_lingkaeCommand: "연동 실행 명령을 선택하십시오!",
            link_deleteTips: "이 연동 규칙을 삭제하시겠습니까?",
        },

        // 경보 관리 - 임시 사용자
        temporarilyUser: {
            tem_receiveSms: "메세지 수신",
            tem_receivePhone: "전화 수신",
            tem_name: "임시 사용자 이름",
            tem_UserPhone: "임시 사용자 전화",
            tem_delectTips: "이 임시 사용자를 삭제하시겠습니까?",
            tem_drawerTitle: "임시 사용자 상세 정보",
            tem_dialogTitle: "신규 임시 사용자",
            tem_rulerNoSpecialStr: "특수 문자를 포함할 수 없습니다!",
            tem_rulerPhoneMax: "전화는 11자리여야 합니다.",
            tem_receive: "받음",
            tem_noReceive: "받지 않음",
            tem_phone: "전화",
            tem_isReceiveSms: "메세지를 수신 하시겠습니까",
            tem_isReceivePhone: "전화를 수신 하시겠습니까 ",
            tem_creater: "만들기",

            rule_length: "길이는 11자리 문자 여야 합니다",
            rule_UserNameNoSpecial: "임시 사용자 이름은 비워둘 수 없습니다",
            rule_phoneNoSpecial: "전화는 비워둘 수 없습니다",
            rule_receiveSms: "메세지 수신 여부를 선택하십시오.",
            rule_receivePhone: "전화 수신 여부를 선택하십시오.",
            rule_createrUserID: "작성자 ID",

            message_addSucc: "신규 임시 사용자 성공!",
            message_addLose: "신규 임시 사용자 실패!",
            // tem_
        },

        // 경보 관리 - 모델 템플릿
        modelNum: {
            model_notificationPla: " 알림 유형",
            model_templateName: "템플릿 이름",
            model_templateNamePla: "템플릿 이름을 입력하십시오.",
            model_deleteTips: "알림 템플릿을 삭제하시겠습니까?",
            model_relevanceModel: "관련 모델",
            model_drawerTitle: "템플릿 연관 모델",
            model_dialogTitleOne: "알림 템플릿 상세 정보",
            model_dialogTItleAdd: "신규 알림 템플릿",
            model_templatArgument: "템플릿 파라메터",
            model_save: "저장",
            model_NewCreater: "지금 만들기",
            model_SelectNotification: "알림 유형 선택",
            model_selectEvent: "사건 유형 선택",
            model_ruleTemplatArgument: "템플릿 파라메터를 입력하십시오.",

            model_phone: "전화",
            model_note: "메세지",
            model_PCPush: "PC 전송",
            model_AppPush: "APP 전송",
            model_OfficialAccounts: "공중호 전송",

            message_saveTemplat: "템플릿 파라메터를 저장하십시오.",
            message_createrSucc: "성 성공!",
            message_createrLose: "생성 실패!",

            relevance_title: "설비 모델 리스트",
            relevance_modelName: "모델 이름",
            relevance_modelNumIntro: "모델 소개",
            relevance_addTips: "알림 템플릿에 모델을 추가하시겠습니까?",
            relevance_cardTitle: "연관된 알림 템플릿의 모델",
            relevance_rmoeverTips: "알림 템플릿에서 모델을 제거하시겠습니까?"
        },

        // 계정 관리 - 회사 관리
        entityManage: {

            entity_name: "회사 이름",
            entity_namePla: "회사 이름을 입력하십시오.",
            entity_addr: "회사 주소",
            entity_addrPla: "회사 주소를 입력하십시오.",
            entity_platformName: "플랫폼 이름",
            entity_platformNamePla: "플랫폼 이름을 입력하십시오.",
            entity_coding: "회사 번호",
            entity_details: "자세한 정보",
            entity_addJunior: "신규 하위",
            entity_drawerTitle: "회사 상세 정보",
            entity_entityNews: "회사 정보",
            entity_confirmEdit: "수정 확인",
            entity_logo: "회사 logo",
            entity_addEntity: "회사 추가",
            entity_platformLogo: "플랫폼 logo",
            entity_superiorsName: "상급 회사",
            entity_oneImg: "로고를 한 장만 업로드할 수 있습니다!"
        },

        // 순검 순찰 - 순검 임무
        patrolTask: {
            task_naemSear: "순찰자 이름 검색",
            task_ssear: "검색",
            task_chargePerson: "담당자",
            task_desc: "임무 설명",
            task_type: "임무 유형",
            task_state: "임무 상태",
            task_notStarted: "시작하지 않음",
            task_underWay: "진행 중",
            task_UnCompleted: "미완성",
            task_completed: "완료됨",
            task_name: "순찰자 이름",
            task_phone: "순찰자 전화",
            task_createrTime: "시작 시간",
            task_deleteTips: "이 순찰 임무를 삭제하시겠습니까?",
            task_deleteBtn: "삭제",
            task_dialogTitle: "신규 순찰 임무",
            task_drawerTitle: "순찰 임무 상세 정보",
            // task_

            add_name: "순찰자",
            add_roleName: "역할 이름",
            add_phone: "휴대폰 번호",
            add_UserNamePla: "사용자 이름을 입력하여 검색하십시오.",
            add_starTime: "시작 시간",
            add_endTime: "종료 시간",
            add_taskLabel: "임무 연관 장소",
            add_selectTime: "날짜 시간 선택",
            add_descPla: "임무 설명을 입력하십시오.",
            add_submitFormBtn: "지금 만들기",
            add_rulesTaskType: "임무 유형을 선택하십시오.",
            add_rulesPatrolMan: "순찰자를 선택하십시오",
            add_rulesOpenTime: "시작 시간과 종료 시간을 선택하십시오.",
            add_rulesEntity: "소속 회사를 선택하십시오.",
            add_ruelsDesc: "임무 설명을 입력하십시오.",
            add_addLose: "신규 실패!",
            add_taskID: "임무 ID",
            add_rulesSite: "장소를 선택하십시오.",

            basic_hiddenTRoubleType: "위험 유형",
            basic_patrolName: "순찰자 이름",
            basic_patrolPhone: "순찰자 전화",
            basic_patrolNameSex: "순찰자 성별",
            basic_alterTime: "시간 변경",
            basic_taskDesc: "임무 설명",
            basic_cardTitle: "순찰 기록",
            basic_createUser: "작성자",
            basic_patrolState: "순찰 상황",

            basic_dialogTitle: "검사 상황",
            basic_dangerLevel: "위험 수준",
            basic_noDanger: "위험 없음",
            basic_oneDanger: "1급 위험",
            basic_twoDanger: "2급 위험",
            basic_threeDanger: "3급 위험",
            basic_handleState: "처리 상태",
            basic_processed: "처리됨",
            basic_pending: "처리 보류 중",
            basic_abnormalTypeName: " 이상 유형 이름",
            basic_checkImg: "검사 사진",
            basic_AfterAbarbeitungImg: "정돈 후 사진",
            basic_noAbarbeitungImg: "정돈 사진 없음",
            basic_uploadingServer: "서버에 업로드 확인",
            basic_abarbeitungImg: "정돈 사진",
            basic_messageOneImg: "먼저 업로드할 사진을 선택하십시오!",
            basic_messageOnlyUpImg: "매번 한 장의 사진만 업로드할 수 있습니다!",

        },

        // 순검 순찰 - 순검 기록
        patrolRecord: {
            record_upLoadingServer: "서버 업로드",
            record_dialogTitle: "순찰 기록 추가",
            record_site: "장소",
            record_belongTask: "소속 임무를 선택하십시오.",
            record_inspectionResult: "순찰 결과",
            record_inspectionResultPla: "순찰 결과를 선택하십시오.",
            record_problemDesc: "문제 설명",
            record_ruleSite: "관련 장소를 선택하십시오",
            record_ruletask: "임무를 선택하십시오",
            record_RuleInspectionResult: "순찰 결과를 선택하십시오.",
            record_ruleRroblemDesc: "문제 설명을 입력하십시오."

        },

        // 순검 순찰 - 임무 유형
        taskType: {
            type_deleteTips: "이 임무 유형을 삭제하시겠습니까?",
            type_deleteBtn: "삭제",
            type_taskTypeName: "작업 유형 이름",
            type_rules: "이상 유형 이름을 입력하십시오.",
            type_dialognTitle: "임무 상세 정보 수정",
            type_addTitle: "신규 임무 유형",
            type_addLose: "신규 실패!",

            type_language: '언어 유형',
            type_languagePla: '언어 유형을 선택하십시오',
            type_languageRule: '언어 유형은 비워둘 수 없습니다',
        },

        // 순검 순찰
        patrolAll: {
            inspectImg: "검사 사진",
            hiddenTroubleBtn: "수정",
            hiddenTroubleBtnTips: "이상 유형을 삭제하시겠습니까?",
            addHiddenTrouble: "신규 이상 유형",
            editTitle: "이상 유형 이름 수정"
        },

        // 시스템 관리
        menuManage: {
            menu_title: "메뉴 제목",
            menu_name: "메뉴 이름",
            menu_path: "메뉴 경로",
            menu_grade: "메뉴 레벨",
            menu_deleteTips: "메뉴를 삭제하시겠습니까?",
            menu_details: "자세한 정보",
            menu_addMenu: "신규 메뉴",
            menu_menuDeatails: "메뉴 상세 정보",
            menu_namePla: "메뉴 이름을 입력하십시오.",
            menu_pathPla: "메뉴 경로를 입력하십시오.",
            menu_titlePla: "메뉴 제목을 입력하십시오.",
            menu_icon: "메뉴 아이콘",
            menu_iconPla: "메뉴 아이콘을 입력하십시오.",
            menu_type: "메뉴 유형",
            menu_typePla: "메뉴 유형을 입력하십시오.",
            menu_module: "메뉴 구성 요소",
            menu_superiorsMenu: "상위 메뉴",
            menu_ruleName: "이름은 비워둘 수 없습니다!",
            menu_rulePath: "메뉴 경로는 비워둘 수 없습니다!",
            menu_ruleLengthMax: "문자수는 1-50자입니다!",
            menu_ruleTitle: "메뉴 제목은 비워둘 수 없습니다.",
            menu_ruleIcon: "메뉴 아이콘은 비워둘 수 없습니다!",
            menu_ruleType: "메뉴 유형은 비워둘 수 없습니다!",
            menu_ruleTypeLength: "문자수는 1-10자 입니다",
            menu_ruleGrade: "메뉴 레벨은 비워둘 수 없습니다",

            messge_deleteSucc: "메뉴 삭제 성공!",
            messge_deleteLose: "메뉴 삭제 실패!",
            messge_addMenuSucc: "신규 메뉴 성공!",
            messge_addMenuLose: "신규 메뉴 실패!",
            messge_editSucc: "메뉴 수정 성공!",
            messge_editLose: "메뉴 수정 실패!",
        },

        // 시스템 관리 - 역할 관리
        roleManage: {
            role_name: "역할 이름",
            role_namePla: "역할 이름을 입력하십시오.",
            role_deleteTips: "역할을 삭제하시겠습니까?",
            role_addRole: "신규 역할",
            role_editRole: "역할 권한 수정",
            role_message: "권한이 수정되지 않았습니다.",
            role_roelMenu: "메뉴 권한",
            role_ruleName: "역할 이름을 입력하십시오.",
            role_messgeEditSucc: "수정 성공!",
            role_messgeEditLose: "수정 실패!",
            role_messageAddSucc: "신규 성공!",
            role_messageAddLose: "신규 실패!",

        },

        // 시스템 관리 - 기록 도출
        exportRecords: {
            export_state: "데이터 상태",
            export_exportMidWay: "도출 중",
            export_exportSucc: "도출 성공",
            export_exportLose: "도출 실패",
            export_downloadTips: "이 데이터를 다운로드하시겠습니까?",
            export_downloadBtn: "다운로드",
            export_deleteTips: "이 데이터를 삭제하시겠습니까?",
            export_name: "데이터 이름",
            floorTitle: "층 평면도",
        },

        // 탐색 모음
        menubar: {
            workbench: "워크스테이션",
            moreHelp: "온라인 문서",
            backstageHomePaee: "백그라운드 첫 페이지",
            cancel: "취소",
            logOut: "로그인을 종료하시겠습니까?",
            backstageManage: "백그라운드 관리",
            SwitchLanguage: "언어 전환",
            equipped: "설비 있음",
            AllEquipment: "모든 설비"
        },

        // 하위 플랫폼 - 스마트 소방
        fireFightingPlatform: {
            fire_title: "스마트 소방 시스템",
            fire_VideoTItle: '비디오 모니터링 플랫폼',
            fire_basicData: "기본 데이터",
            fire_alarmData: "경보 데이터",
            fire_account: "계정 관리",
            fir_map: "경보 지도",

            left_alarmRecords: "경보 기록",
            left_faultRecords: "고장 기록",
            left_deviceManage: "설비 관리",
            left_siteUnit: "장소/건축물",
            left_floorList: "층 리스트",
            left_installPointManage: "설치 지점 관리",
            left_patrolTask: "순찰 임무",
            left_patrolRecords: "순찰 기록",
            left_patrolState: "잠재적 위험 상황",
            left_patrolType: "위험 유형",
            left_entityManage: "회사 관리",
            left_userManage: "사용자 관리",
            left_deviceStatistics: "설비 집계",
            left_alarmStatistics: "경보 집계",
            left_faultStatistics: "고장 집계",
            left_patrolTrouble: "잠재적 위험 / 순찰 집계",
            left_devcieStateStatistics: "설비 상태 집계",
            left_troubleSatistics: "잠재적 위험 집계",
            left_installStatistics: "설치 집계",
            left_patrolStatistics: "순찰 집계",

            map_deviceDetails: "설비 상세 정보",
            map_runData: "운행 데이터",
            map_orderTips: "이 명령을 켜시겠습니까?",
            map_Controls: "작업",
            map_signal: "신호 강도",
            map_voltage: "전압",
            map_temperature: "온도",
            map_concentration: "농도값",
            map_installTime: "설치 시간",
            map_noOrder: "이 장치에 하달할수 있는 명령이 없습니다.",

            map_floorNum: "층수",
            map_installPoint: "지점 개수",
            map_go: "가기",


            Gas_title: "가스 안전 시스템",
            PRoduction_title: "생산 관리 시스템",
            patrol_title: "순찰 관리 시스템",
            patrol_taskType: "임무 유형",
            patrol_checkstate: "검사 상태",
            patrol_type: "이상 유형",
            map_deviceModelNum: "설비 모델",
            Production_deviceModel: "설비 모형",
            Production_modelNum: "모델 지령",
            Production_PatrolRes: "품질 검사 기록",
            Production_devicePatrol: "설비 품질 검사",
            Production_deviceImport: "설비 가져오기",
            Production_zuce: "등록 기록",
            Production_zuxiao: "로그아웃 기록",


        },

        // 하위 플랫폼 - 서향가스, 스마트 소방
        xiXiangGasPlatform: {
            "xiXiang_PersonalMessage": "개인 정보",
            "xiXiang_Subscribers": "사용자",
            "xiXiang_Caption": "제목",
            "xiXiang_CreationTime": "생성 시간",
            "xiXiang_ReadingSituation": "읽기 상태",
            "xiXiang_Particulars": "자세한 정보",
            "xiXiang_Operation": "작업",
            "xiXiang_tipsReadMessage": "이 메세지를 읽으셨습니까?",
            "xiXiang_tipsReadBtnTitle": "읽기",
            "xiXiang_tipsIsRead": "읽음",
            "xiXiang_tipsUnReadBtnTitle": "읽지 않음",
            "xiXiang_Cancellations": "취소",
            "xiXiang_MakeCertain": "확인",

            "xiXiang_HomePage": "홈 페이지",
            "xiXiang_EquipmentManagement": "설비 관리",
            "xiXiang_WorkOrderManagement": "작업 명세서 관리",
            "xiXiang_OperationAndMaintenanceReport": "운영보수 보고서",
            "xiXiang_MerchantManagement": "상인 관리",
            "xiXiang_systemManagement": "시스템 관리",
            "xiXiang_UserManagement": "사용자 관리",
            "xiXiang_MessageManagement": "메시지 관리",
            "xiXiang_LoginLog": "로그인 일지",
            "xiXiang_OperatingLog": "작업 일지",

            "xiXiang_BigSmartScreen": "스마트 스크린",
            "xiXiang_DecisionMakingCommandAtAGlance": "한 페이지에 결책지휘 보기",
            "xiXiang_RoutineInspection": "일상 순찰",
            "xiXiang_ViewInspectionCompletionRates": "순찰 완료율 보기",
            "xiXiang_ViewO&MReports": "운영보수 보고서 보기",
            "xiXiang_WorkOrderReporting": "작업 명세서 에스컬레이션",
            "xiXiang_ViewWorkOrderReporting": "작업 명세서 에스컬레이션 보기",

            "xiXiang_PercentageOfEquipment": "설비 점유 비율",

            "xiXiang_EquipmentOverview": "설비 개요",
            "xiXiang_TotalEquipment": "총 설비 수",
            "xiXiang_CallThePoliceToday": "오늘 경보",
            "xiXiang_TroubleshootingToday": "오늘 고장",
            "xiXiang_InstallationToday": "오늘 설치",
            "xiXiang_MaintenanceToday": "오늘 유지보수",

            "xiXiang_EquipmentOnlineRate": "설비 온라인 비율",
            "xiXiang_EquipmentOfflineRate": "설비 오프라인 비율",
            "xiXiang_EquipmentFailureRate": "설비 고장 비율",
            "xiXiang_EquipmentAlarmRate": "설비 경보 비율",

            "xiXiang_AlarmOverview": "경보 개요",
            "xiXiang_TotalNumberOfAlarms": "총 경보 수",
            "xiXiang_AlarmsProcessed": "기 처리 경보",
            "xiXiang_UnprocessedAlarms": "미 처리 경보",


            "xiXiang_Faultprofile": "고장 개요",
            "xiXiang_TotalNumberOfFaults": "총 고장 수",
            "xiXiang_Troubleshooting": "기 처리 고장",
            "xiXiang_UntreatedFaults": "미 처리 고장",

        },

        //스마트 소방지도
        fireControl: {
            map_standardColor: "표준 색상",
            map_blue: "나이트 블루",
            map_cyan: "인디고 남",
            map_gray: "아스트 그레이",
            map_black: "팬텀 블랙",
            map_silver: "달빛 실버",

            map_contacts: " 연락처",
            map_contactsPhone: "연락처 전화",
            map_NameOfPlace: "장소 이름",
            map_latitude: "위도",
            map_longitude: "경도",
            map_siteBasicTitle: "장소 기본 정보",
            map_deviceListTitle: "설비 리스트",
            map_deviceNum: "설비 번호",
            map_deviceModelNum: "설비 모델",
            map_mapStyle: "지도 스타일 수정",
            map_goBacSitekNews: "장소 정보로 돌아가기",
            map_messageSite: "장소를 먼저 선택하십시오!",
            map_siteEntity: "회사",
            map_unit: "건물",
            map_pushTitle: "전송 기록",
            map_alarmHandle: "경보 처리",

            map_state: "상태",

            map_select: "선택하신것은",

            map_placePoint: "지점 배치",
            map_restPoint: "지점 재설정",
            map_confirmEditPoint: "지점 수정 확인",
            map_palceTIpes: "평면도 내부를 클릭하고 배치 후 지점 위치를 저장하십시오",
            map_firemAlarmSystem: "지도",

        },

        all: {
            deviceModel_typeName: "유형 이름",
            deviceModel_typeNameRule: "유형 이름은 비워둘 수 없습니다",

            deviceCheck_tips: "고객을 가져오시겠습니까?",
            deviceCheck_normalDevcieSucc: "정상 설비 가져오기 성공!",
            deviceCheck_normalDevcieLose: "정상 설비 가져오기 실패!",
            deviceCheck_selectDevice: "정상 설비의 회사를 먼저 선택하십시오!",
            deviceCheck_abnormalDevcieSucc: "이상 설비 가져오기 성공!",
            deviceCheck_abnormalDeviceLose: "이상 설비 가져오기 실패!",
            deviceCheck_selectAbnormalDevcie: "이상 설비의 회사를 먼저 선택하십시오!",

            AlarmPlatform_deviceNo3D: "이 설비는 3D 지도와 연결되어 있지 않습니다",
            AlarmPlatform_devcieNoModel: "이 설비는 3D 모형과 연관되어 있지 않습니다.",
            AlarmPlatform_alarmBanister_noCHeck: "경보 접수란이 유휴 상태여서 볼 수 없습니다",
            AlarmPlatform_noSpacing: "입력에 공백을 포함할 수 없습니다.",
            AlarmPlatform_noDeviceType: '설비 유형 없음',
            AlarmPLatform_noAlarmLog: '경보 기록 없음',



            DutyLogManage_messageLogsNoSpacing: "일지 내용이 모두 공백일 수 없습니다!",
            DutyLogManage_messageLog: "일지 내용이 모두 공백일 수 없습니다!",
            DutyLogManage_messageAddSucc: "신규 성공!",
            DutyLogManage_messageAddLose: "신규 실패!",

            entity_enttiyName: "회사 이름",
            entity_enttiyNameRule: "회사 이름을 입력하십시오.",
            entity_platformLogName: "플랫폼 이름",
            entity_platformLogNameRule: "플랫폼 이름을 입력하십시오.",
            entity_platformLogNameRule_noSpace: "플랫폼 이름은 비워둘 수 없습니다.",
            entity_clcikMapPla: '설비 보기를 클릭하면 지도에 회사 설비가 표시됩니다',
            entity_clearImp: '리스트 사진 삭제 성공!',
            // DutyLogManage_
            // DutyLogManage_


            //用户管理
            userManage_loginNamePla: "11자리 전화번호 또는 문자와 숫자로 구성",
            userManage_passwordPla: "암호를 입력하십시오.",
            userManage_UserNnamePla: "이름을 입력하십시오",
            userManage_PhonePla: "연락처 전화를 입력하십시오",
            userManage_genderPla: "성별을 선택하십시오.",
            userManage_rolePla: "역할을 선택하십시오.",
            userManage_entityPla: "소속 회사를 선택하십시오",
            userManage_registerPla: "로그인 여부를 선택하십시오.",
            userManage_imgUpData: '업로드 파일 선택',
            userManage_imgTitle: '업로드된 검사 사진',
            userManage_superUser: '슈퍼 관리자',
            userManage_produceUser: '생산 관리자',
            userManage_appletUser: '애플릿 관리자',
            userManage_unitUser: '회사 관리자',
            userManage_addUserSucc: '신규 사용자 성공',
            userManage_userExist: '이 계정은 이미 있습니다',
            userManage_addUserLose: '신규 사용자 실패',

            // 설치 지점 관리
            pointManage_pointAddrRule: "지점 주소는 비워둘 수 없습니다.",
            pointManage_pointNameRule: "지점 이름은 비워둘 수 없습니다.",


            // 대형 디지털 화면
            Digital_Online_rate: "온라인 비율",
            Digital_mapCheckDevcie: '설비 보기',
            Digital_mapCheckFloor: '층 보기',

            // 임시 사용자
            temporary_phoneChangPla: '수신 전화를 수정하시겠습니까?',
            temporary_smsChangPla: '메세지 수신을 수정하시겠습니까?',

            // 바인딩
            devcieBind_seleceMap: '지도 위치를 선택하십시오!',
            devcieBind_seleceMapLose: '장소를 선택하거나 장소를 새로 만드십시오!',

            // 경보 기록
            alarmRecord_allAlarmState: '모든 경보 상태',

            // 연동 규칙
            linkRule_openState: '이 켜진 상태를 수정하시겠습니까?',

            noData_threeDModel: '3D 모형 없음',
            noDevcieData: '설비 데이터 없음',

            video_start: '시작',
            video_suspend: '일시 중지',
            video_multiple: '플레이 속도 배율',
            video_control: '비디오 컨트롤',
            video_playVideo: '플레이',
            video_refresh: '새로 고침',
            video_dataName: '파일 이름',
            video_messaeErr: '재생 가져오기 실패!새로 고침 버튼을 클릭하십시오!',
            video_palyBalck: '비디오 재생',
            videe_levelShift: '수평 이동 속도',
            videe_verticalShift: '수직 이동 속도',
            vide_videoManipulation: '모니터링 작업',
            vide_regionalSetting: '구역 설정',
            vide_clearCanvas: '캔버스 지우기',
            vide_saveCoordinate: '좌표 저장',
            OpenPerimeter: '주계 열기',
            ClosePerimeter: '주계 닫기',
            setPerimeterErr: "주계 설정 실패",
            vide_morePoints: '최대 6개 포인트를 설정할 수 있습니다!',
            vide_leastPoints: '최소 3개 포인트 필요합니다!',
            vide_savePointsOk: '설정 유효, 저장 준비',
            vide_savePointsError: '이 영역은 유효하지 않습니다. 다시 설정하십시오',
            video_loding: '비디오 리스트를 로드 중...',

            videoSys_enven: '사건',
            videoSys_img: '사진',
            videoSys_online: '온라인',
            videoSys_downline: '오프라인',
            videoSys_fault: '고장',
            videoSys_alarm: '경보',
            videoSys_heartbeat: '심장박동',
            videoSys_flameAlarm: '화염 감지 경보',
            videoSys_smokeAlarm: '연기 감지 경보',
            videoSys_flameSomkeAlarm: '화재 감지 경보(화염+연기)',
            videoSys_electrocarAlarm: '전기차 식별 경보',
            videoSys_unknownEvent: '알 수 없는 사건',
            videoSys_heartbearEvent: '심장박동 사건',
            videoSys_faceRecognition: '안면인식',

            videoSys_loading: '로드 중...',
            videoSys_devcieDownLin: '이 설비는 온라인 상태가 아닙니다',
            videoSys_allVideoList: '모든 데이터가 로드됨',
            videoSys_videoControls: '카메라 작업',
            videoSys_title: ' 스마트 보안 클라우드 플랫폼 비디오 모니터링 시스템',
            videoSys_fullScreen: '전체 화면',
            videoSys_clearAll: '전부 끄기',
            videoSys_passage: '채널',
            videoSys_loadingTwo: '로드 중...',
            videoSys_quitFullScren: '전체 화면 나가기',
            videoSys_playLose: '플레이 실패 하였습니다, 삭제 후 설비를 다시 선택 하십시오',
            // videoSys_alarm:'',
            // videoSys_alarm:'',
            // videoSys_alarm:'',
            // videoSys_alarm:'',
            imgae_floor: '먼저 지점 위치를 수정하십시오',

            devcieDetails_smokeArgument: '연기 감지 호스트 파라메터',
            devcieDetails_smokeHardware: '하드웨어 버전',
            devcieDetails_smokeSoftWare: '소프트웨어 버전',
            devcieDetails_smokeBu: '방비 해제/배치',
            devcieDetails_smokeSeparate: '정상/분리',
            devcieDetails_smokeAudio: '사운드/소음',
            devcieDetails_smokeErasureAudio: '미 소음/소음',
            devcieDetails_smokeOpen: '밸브 닫기/열기',
            devcieDetails_smokeMessErr: '연기감지기 호스트 파라메터 가져오기 실패',
            devcieDetails_smokeBind: '바인딩 됨',
            devcieDetails_smokeNoBind: '바인딩 되어있지 않습니다.',
            devcieDetails_smokeIsBind: '바인딩 하시겠습니까',
            devcieDetails_smokeChangerAddr: '주소 및 지점 수정',
            devcieDetails_smokeAddr: '주소',
            devcieDetails_smokeAddrRule: '지점의 경도 위도는 비워둘 수 없습니다',
            devcieDetails_smokeAddrPla: '주소를 입력하십시오',
            devcieDetails_smokePlachangePoint: '지도 지점을 먼저 수정하십시오',
            devcieDetails_smokePointTip: '지도의 태그를 드래그하여 지점 위치를 수정하십시오',
            devcieDetails_addSmokePointTip: '지도를 클릭하여 지점을 지정을 해주세요',
            devcieDetails_smokeBian: '편집',
            devcieDetails_smokePointChange: '지점을 수정해주세요!',

            mqtt_deviceAlarm: '설비 경보',
            mqtt_deviceAlarmDetail: '경고 상세 정보',
            mqtt_checkVideo: '비디오 보기',
            mqtt_model: '3D 모형',

            siteNoEmpty: '장소는 비워둘 수 없습니다!',
            time: '시간',

            site_businessHours: '영업시간',
            site_businessState: '영업 상태',
            site_businessStatePla: '운영 상태를 선택하십시오',
            site_businessStateOpt1: '정상 영업',
            site_businessStateOpt2: '휴업정돈',
            site_businessStateOpt3: '존속',
            header_closeBlackAlarmDevice: "설비 경고 블랙리스트",
            header_alarmBell: '경보벨소리',
            header_alarmRadio1: '경보 주소 방송',
            header_alarmRadio2: '경보벨소리 방송',

            exportAll_pla: '현재 조건의 모든 데이터를 도출하시겠습니까?',
            exportAll_btn: '전부 도출',


            user_name: '소유자 이름',
            user_namePla: '소유자 이름을 입력하십시오',
            user_phone: '소유자번호',
            installationPersonnel: "설치자",
            installationPersonnel_input: "설치자 이름을 입력하십시오.",

            user_phonePla: '소유자 전화를 입력하세요',
            install_personnel: "설치자",

            install_time: '설치 시간',

            site_nameLength: '길이는 50자 이하여야 합니다',

            upData_imgPla: ' .png 또는.jpg 형식의 사진을 업로드하십시오!',

            alarm_log_title: "현재 경보 기록이 없습니다."
        },

        batchBind: {
            detail: "바인딩 결과",
            getDetailFailure: "바인딩 결과 조회 실패",
            addTitle: "대량 바인딩",
            entity: "회사 바인딩",
            message_importEntity: "바인딩 회사는 비워둘 수 없습니다."
        }

    },

    cameraSetting: {
        "ReplayDate": "비디오 재생일자",
        "Dispatch": "전송",
        "StartIntercom": "통화 시작",
        "Intercom": " - 통화 중...",
        "Broadcast": "방송",
        "StopBroadcast": "방송 중지",
        "onRelay": "원격 릴레이 켜기",
        "offRelay": "원격 릴레이 끄기",
        "onAlgorithm": "알고리즘 릴레이 켜기",
        "offAlgorithm": "알고리즘 릴레이 끄기",
        "RelaySettings": "릴레이 설정",
        "MovementSpeed": "이동 속도",
        "MovementDistance": "이동 거리",
        "Movement": "이동 거리 켜기 / 끄기",
        "PTZControl": "패닉 제어",
        "SetArea": "구역 설정",
        "BasicParameters": "기본 매개변수",
        "PushNotifications": "푸시 정보",
        "OtherParameters": "기타 매개변수",
        "VideoList": "녹화 목록",
        "ServerIP": "서버 IP 주소",
        "ServerPort": "서버 포트",
        "ServerID": "서버 ID",
        "ServerPwd": "서버 비밀번호",
        "DeviceID": "장비 ID",
        "DeviceName": "장비 이름",
        "CameraType": "카메라 유형",
        "PleaseSelect": "선택하시기 바랍니다",
        "BulletCamera": "가정용 카메라",
        "DomeCamera": "반구형 카메라",
        "SpeedDome": "구형 카메라",
        "ZoomableCamera": "줌 조절 구형 카메라",
        "DeviceUsername": "장비 사용자 이름",
        "DevicePwd": "장비 비밀번호",
        "ScreenshotTime": "스크린샷 간격 시간",
        "SendInterval": "전송 간격 시간",
        "SlaveID": "슬레이브 번호",
        "LORAMode": "LORA 모드",
        "SlaveMode": "슬레이브 모드",
        "MasterMode": "마스터 모드",
        "IPNotEmpty": "서버 IP 주소는 비워두면 안됩니다",
        "PortNotEmpty": "서버 포트는 비워두면 안됩니다",
        "IDNotEmpty": "서버 ID는 비워두면 안됩니다",
        "pwdNotEmpty": "서버 비밀번호는 비워두면 안됩니다",
        "dIDNotEmpty": "장비 ID는 비워두면 안됩니다",
        "NameNoEmpty": "장비 이름은 비워두면 안됩니다",
        "TypeNoEmpty": "카메라 유형은 비워두면 안됩니다",
        "unameNoEmpty": "장비 사용자 이름은 비워두면 안됩니다",
        "pwdNoEmpty": "장비 비밀번호는 비워두면 안됩니다",
        "ScreenshotNoEmpty": "스크린샷 간격 시간은 비워두면 안됩니다",
        "SendNoEmpty": "전송 간격 시간은 비워두면 안됩니다",
        "SlaveIDNoEmpty": "슬레이브 번호는 비워두면 안됩니다",
        "LORANoEmpty": "LORA 모드 선택은 비워두면 안됩니다",
        "VideoParameters": "비디오 매개변수",
        "Resolution": "해상도",
        "HD": "고화질",
        "SD": "표준 해상도",
        "Smooth": "원활한",
        "Bitrate": "비트레이트",
        "BitrateNoEmpty": "비트레이트는 비워두면 안됩니다",
        "AudioParameters": "오디오 매개변수",
        "SpeakerVolume": "스피커 볼륨",
        "MicVolume": "마이크 볼륨",
        "SpeakerVNoEmpty": "스피커 볼륨은 비워두면 안됩니다",
        "MicVNoempty": "마이크 볼륨은 비워두면 안됩니다",
        "ISPInfo": "ISP 정보",
        "Contrast": "대비",
        "Brightness": "밝기",
        "Sharpness": "선명도",
        "Saturation": "채도",
        "ContrastNoEmpty": "대비 범위는 0256이며 비워두면 안됩니다",
        "BrightnessNoEmpty": "밝기 범위는 0256이며 비워두면 안됩니다",
        "SharpnessNoEmpty": "6且不能为空 - 선명도 범위는",
        "SaturationNoEmpty": "채도 범위는 0256이며 비워두면 안됩니다",
        "WIFIHotspot": "WIFI 핫스팟",
        "EncryptionMethod": "암호화 방식",
        "SSID": "SSID",
        "WIFIPwd": "WIFI 비밀번호",
        "enterEethod": "암호화 방식을 입력하시기 바랍니다",
        "enterSSID": "SSID를 입력하시기 바랍니다",
        "enterPWD": "WIFI 비밀번호를 입력하시기 바랍니다",
        "MethodNoEmpty": "암호화 방식은 비워두면 안됩니다",
        "SSIDNoEmpty": "SSID는 비워두면 안됩니다",
        "WIFIPwdNoEmpty": "WIFI 비밀번호는 비워두면 안됩니다",
        "HTTPPush": "HTTP 푸시",
        "enterURL": "URL을 입력하시기 바랍니다",
        "enterToken": "토큰을 입력하시기 바랍니다",
        "enterTokenKey": "토큰 키를 입력하시기 바랍니다",
        "URLNoEmpty": "URL은 비워두면 안됩니다",
        "TokenNoEmpty": "토큰은 비워두면 안됩니다",
        "TokenKeyNoEmpty": "토큰 키는 비워두면 안됩니다",
        "EmailPush": "이메일 푸시",
        "ServiceAddress": "서비스 주소",
        "Port": "포트",
        "Subject": "주제",
        "enterMQTTaddress  ": "MQTT 서비스 주소를 입력하시기 바랍니다",
        "enterMQTTport": "MQTT 포트를 입력하시기 바랍니다",
        "enterusername": "사용자 이름을 입력하시기 바랍니다",
        "enterSubject": "주제를 입력하시기 바랍니다",
        "MQTTServiceNoEmpty": "MQTT 서비스 주소는 비워두면 안됩니다",
        "MQTTPortNoEmpty": "MQTT 포트는 비워두면 안됩니다",
        "SubjectNoEmpty": "주제는 비워두면 안됩니다",
        "enterPushStatus": "푸시 상태를 입력하시기 바랍니다",
        "SerialPort": "시리얼 포트 투윈",
        "SerialPortAddress": "시리얼 포트 주소",
        "Token": "토큰",
        "FTPNoEmpty": "FTP 주소는 비워두면 안됩니다",
        "uNameNoEmpty": "사용자 이름은 비워두면 안됩니다",
        "enterAddress": "시리얼 포트 주소를 입력하시기 바랍니다",
        "SerialNotEmpty": "시리얼 포트 주소는 비워두면 안됩니다",
        "FireAlarmParameters": "화재 경보 매개변수",
        "FlameConfidence": "불꽃 인식 신뢰도",
        "SmokeConfidence": "연기 인식 신뢰도",
        "AlarmThreshold": "경보 임계값",
        "enteralarm": "경보 임계값을 입력하시기 바랍니다(숫자만 입력하십시오)",
        "AlarmNotEmpty": "경보 임계값은 비워두면 안됩니다",
        "Parameters": "전기자동차 인식 매개변수",
        "HumanRecognition": "인체 인식 신뢰도",
        "Confidence": "전기자동차 인식 신뢰도",
        "Bicycle": "자전거 인식 신뢰도",
        "enterZQNumber": "올바른 숫자를 입력하시기 바랍니다",
        "enterNumber": "11000 사이의 숫자를 입력하시기 바랍니다",
        "notImplemented": "대화 기능은 아직 구현되지 않았습니다",
        "FailedModify": "매개변수 수정 실패",
        "OnlyMP3files": "mp3 파일만 업로드할 수 있습니다",
        "sureuploadfile": "이 방송 파일을 업로드하시겠습니까 ?",
        "FailedopenNormally": "열기 실패, 비디오 정상 재생 후 설정하십시오!",
        "SettingsSuccessful": "설정 성공",
        "SettingsFailed": "설정 실패",
        "RequestError": "요청 오류, 작업자에게 연락하십시오!!!",
        "enterIPAddress": "서버 IP 주소를 입력하시기 바랍니다",
        "enterServerPort": "서버 포트를 입력하시기 바랍니다",
        "enterServerID": "서버 ID를 입력하시기 바랍니다",
        "enterServerPwd": "서버 비밀번호를 입력하시기 바랍니다",
        "enterDeviceID": "장치 ID를 입력하시기 바랍니다",
        "enterDeviceName": "장치 이름을 입력하시기 바랍니다",
        "enterDeviceUname": "장치 사용자 이름을 입력하시기 바랍니다",
        "enterDevicePwd": "장치 비밀번호를 입력하시기 바랍니다",
        "enterScreenshot": "스크린샷 간격 시간을 입력하시기 바랍니다(단위: 초)",
        "enterSending": "전송 간격 시간을 입력하시기 바랍니다(단위: 초)",
        "enterStack": "스택 번호를 입력하시기 바랍니다",
        "FrameRate": "프레임률",
        "Email": "이메일",
        "enterEmail": "이메일 주소를 입력하시기 바랍니다",
        "emailNoEmpty": "이메일 주소는 비워두면 안됩니다",
        "Switch": "스위치",
        "FTPpush": "FTP 푸시",
        "FTPaddress": "FTP 주소",
        "enterFTP": "FTP 주소를 입력하시기 바랍니다",
        "enterSerial": "시리얼 포트 주소를 입력하시기 바랍니다",
        "MQTTPush": "MQTT 푸시",
        "enterMQTT": "MQTT 서비스 주소를 입력하시기 바랍니다",
        "enterMQTTProt": "MQTT 포트를 입력하시기 바랍니다",
        "robinTime": "루프 시간",
        "beginRobin": "루프 시작",
        "endRobin": "루프 중지",
        "verticalSpeek": "수직 이동 속도",
        "levelSpeek": "수평 이동 속도",
        "splitScreen": "화면 전환",
        "fullScreen": "전체 화면",
        "closeAll": "모두 닫기",
        "close": "닫기",
        "screenshot": "스크린샷",
        "beginVideo": "녹화 시작",
        "cloudControl": "팬 & 틸ルト 제어",
        "showSite": "설치 위치 표시",
        "endVideo": "녹화 중지",
        "enterTime": "시간을 입력해주세요",
        "SplitScreen": "분할 화면",
        "Rotation30": "루프 시간은 30초 이하로 설정할 수 없습니다",
        "handleAlarm": "먼저 경보를 처리해주세요",
        "stopPotation": "현재 녹화 중이므로 루프를 수행할 수 없습니다. 녹화를 중지한 후 루프를 시작해주세요",
        "currentRotation": "현재 장치 수가 부족하여 루프를 수행할 수 없습니다",
        "RotationActive": "현재 루프 상태에서 창 전환은 금지되어 있습니다",
        "FailedgetList": "녹화 목록 검색 실패...",
        "notSwitching": "현재 루프 상태에서 비디오 재생 전환은 금지되어 있습니다",
        "selectWindow": "재생 창을 선택해주세요",
        "currentWindow": "현재 장치가 창에 활성화되어 있습니다",
        "Play": "재생",
        "VOD": "VOD 실패, 카메라 상태를 확인해주세요",
        "HideLocation": "설치 위치 숨기기",
        "ShowLocation": "설치 위치 표시",
        "RotationIs": "현재 루프 상태에서 버튼 클릭은 금지되어 있습니다",
        "NoVideo": "현재 비디오 재생이 없어서 닫을 필요가 없습니다",
        "ClosedSucc": "성공적으로 닫음",
        "unableRecord": "현재 창에 비디오 재생이 없어서 녹화할 수 없습니다",
        "CurrentOffline": "현재 장치가 온라인에 연결되어 있지 않습니다",
        "Window": "창",
        "isProgress": "녹화 중입니다",
        "openS": "열",
        "closeS": "관",
        "loading": "불러오는 중...",
        "recordSucc": "녹화가 성공적으로 되었습니다",
    },

    // 설비 상세 정보 -> 스마트 흉패 모듈
    smartBadgeModule: {
        smartBadge: "스마트 흉패 ", 
        basicData: "기본 데이터",
        smartBradgePhone: "스마트 흉패 번호",
        SmartBadgeWIFIScan: "스마트 흉패 WIFIScan",
        SmartBadgeClassRoomMode: "스마트 흉패 - 교실 모드",
        SmartBadgeAlarmClock: "스마트 흉패-알람",
        SmartBadgeCallRecord: "스마트 흉패 통화 기록",
        SmartBadgeLbs: "스마트 흉패-lbs",
        SmartBadgeStepLogs: "스마트 흉패 걸음보수 기록",
        SmartBadgeGnssRecords: "스마트 흉패 gnss 기록",
        SmartBadgeGnssFenceRecords: "스마트 흉패 gnss_fence 기록",
        BasicInformation: "기본 정보",
        SOSalarmOn: "SOS 경보 켜짐",
        SOSalarmOff: "SOS 경보 끄기",
        EnableAudioCalibration: "오디오 교정 켜기",
        AudioCalibrationComplete: "오디오 교정 완료",

        "AlarmClock": "알람 시계",
        "selectTime": "시간을 선택해 주세요",
        "content15": "벨소리 내용 길이가 15자 이상입니다",
        "Effectivetime": "유효 시간",
        "Duration": "지속 시간/초",
        "RingtoneContent": "벨소리 내용",
        "RepeatDateView": "반복 날짜 보기",
        "RepeatCycle": "반복 주기",
        "RingtoneName": "벨소리 이름",
        "alarmNoEmpty": "알람 시작 시간은 비워 두면 안 됩니다",
        "AlarmNumber": "알람 시계 번호",
        "EffectiveStatus": "유효 상태",
        "startTime": "알람 시작 시간",
        "AlarmDuration": "알람 지속 시간",
        "Monday": "월요일",
        "Tuesday": "화요일",
        "Wednesday": "수요일",
        "Thursday": "목요일",
        "Friday": "금요일",
        "Saturday": "토요일",
        "Sunday": "일요일",
        "Effective": "유효",
        "noNull": "비워둘 수 없습니다.",

        "fenceMode": "전자울타리 모드 설정",
        "setVoice": "음성 메시지 재생 설정",
        "LocationData": "위치 데이터",
        "playRingtone": "벨소리 재생",
        "RestoreFactory": "팩토리 리셋",
        "otherSettings": "기타 설정",
        "the": "제",
        "gear": "단",
        "exceedingT": "임계값 초과 보고",
        "belowT": "임계값 미만 보고",
        "pageLost": "아이구, 페이지가 손실되었습니다",
        "BatteryInfo": "전류 정보",
        "factory": "팩토리 리셋을 확정하시겠습니까 ?",
        "characters30": "재생 콘텐츠 길이는 30자 이하로 해야 합니다.",
        "HeartbeatTime": "심박 시간(단위: 초)",
        "SOSAlarm": "SOS 경보 상태",
        "SOSAlarmOn": "SOS 경보 켜기",
        "SOSAlarmOff": "SOS 경보 끄기",
        "CountryCode": "국가 코드",
        "CarrierCode": "운영업체 코드",
        "BaseStation": "기지국",
        "residential": "주거지역",
        "VolumeLevel": "음량",
        "SilentMode": "무음 모드",
        "AudioCalibration": "오디오 캘리브레이션",
        "NotCalibrated": "캘리브레이션 되지 않음",
        "Calibrating": "캘리브레이션 중",
        "UnreadSMS": "미읽 짧은 메시지",
        "PedometerSensitivity": "걸음 수 계산기 민감도",
        "ElectronicMode": "전자울타리 모드",
        "HeartbeatPriority": "심박 위치 우선 순위",
        "VoltageStatus": "전압 상태",
        "Voltage": "전압",
        "LowVoltage": "저 임계값 전압",
        "HighVoltage": "고 임계값 전압",
        "BatteryStatus": "전류 상태",
        "BatteryLevel": "전류",
        "LowLevel": "저 임계값 전류",
        "HighLevel": "고 임계값 전류",
        "PlaybackContent": "재생 콘텐츠",
        "enterEontent": "재생 콘텐츠를 입력하십시오.",
        "PlaybackType": "재생 유형",
        "PlayImmediately": "즉시 재생",
        "Sendprompts": "장치로 미읽 음성 메시지를 보내기, 장치는 수신 알림이지만 재생하지 않음",

        "positionS": "위치 지정 성공",
        "positionF": "위치 지정 실패",
        "playS": "재생 성공",
        "playF": "재생 실패",

        "CallRecordInfo": "스마트 브로커 통화 기록 정보",
        "I_O_Indicator": "호출 플래그",
        "CallStatus": "통화 상태",
        "CallDuration": "통화 시간 (초)",
        "CallTime": "통화 시간",
        "Inbound": "호출",
        "Outbound": "호출",
        "NotConnected": "연결되지 않음",
        "Connected": "연결",
        "ClassroomMode": "스마트 브로커 - 교실 모드",
        "SetCM": "교실 모드를 설정하십시오",
        "ClassroomNum": "교실 번호",
        "STCBE": "시작 시간은 비워 둘 수 없습니다",
        "ETCBE": "종료 시간은 비워 둘 수 없습니다",
        "gnssInfo": "스마트 브로커 Gnss 기록 정보",
        "TAM": "시간 분",
        "HDOP": "수평 정밀도 인자(HDOP)",
        "Altitude": "해발고도",
        "PositionType": "위치 유형",
        "MotionAngle": "운동 각도",
        "HorizontalVelocity": "수평 운동 속도",
        "CurrentDate": "현재 날짜",
        "NOSUIP": "위치 결정에 참여한 위성 수",
        "DPI": "차분 위치 결정 식별자",
        "NotPositioned": "위치 결정되지 않음",
        "Positioning2D": "2D 위치 결정",
        "Positioning3D": "3D 위치 결정",
        "Invalid": "무효",
        "SPP": "단일 포인트 위치 결정",
        "DP": "차분 위치 결정",
        "gnssRecord": "스마트 브로커 Gnss 기록 정보",
        "height": "고도",
        "lbsInfo": "스마트 브로커 LBS 정보",
        "recordTime": "기록 시간",
        "phoneInfo": "스마트 브로커 번호 정보",
        "setPhone": "가족 번호 설정",
        "phoneNum": "번호 ID",
        "phone": "휴대폰 번호",
        "phoneType": "번호 유형",
        "abPhone": "가족 번호",
        "SOSPhone": "SOS 번호",
        "whitePhone": "화이트리스트",
        "StepRecord": "스마트 브로커 걸음 수 기록 정보",
        "stepCount": "걸음 수",
        "WiFiScan": "스마트 브로커 WIFIScan",
        "wifiStrength": "신호 강도",
    },
    mqttConnect: {
        // "success": "success",
        "success": "연결 상태: 연결 성공",
        "fail": "연결 상태: 연결 실패",
    },
    sleepRadar: {
        Out_of_Bounds : "위치 오프사이드 상태",
        existInfo: "존재 정보",
        sportsInfo: "운동 정보",
        heartRate: "심박수 수치",
        breatheInfo: "호흡 정보",
        breatheNum: "호흡 수치",
        In_and_out_of_bed: "침대 오름/내림 상태",
        sleepState: "수면 상태",
        soberDuration: "깨어있는 시간",
        lightSleepDuration: "얕은 수면 시간",
        deepSleepDuration: "깊은 수면 시간",
        SleepQualityRating: "수면 품질 등급",
        parahypnosis: "수면 이상",
        AbnormalStruggleState: "이상 몸부림 상태",
        none: "무",
        outsideTheRange: "범위 밖",
        WithinTheRange: "범위 내",
        unmanned: "사람 없음",
        someone: "사람 있음",
        static: "정지",
        active: "활약",
        normal: "정상",
        hyperapnea: "호흡이 너무 큼",
        hypopnea: "호흡이 너무 작음",
        leaveTheBed: "침대를 떠남",
        getIntoBed: "침대에 오름",
        deepSleep: "깊은 잠",
        lightSleep: "얕은 잠",
        sober: "깨여 있음",
        minute: "분",
        GoodSleepQuality: "면질이 양호 함",
        AverageSleepQuality: "수면질이 보통 임",
        PoorSleepQuality: "수면질이 차 함",
        SleepLessThanHours: "수면 4시간 미만",
        SleepLongerThanHours: "수면 시간 12시간 이상",
        longTimeNoOne: "장시간 동안 이상하게 사람 없음",
        normalState: "정상 상태",
        HumanExistence: "스위치 인체 존재",
        heartRateMonitoring: "심박수 모니터링 기능 개폐",
        respiratoryMonitor: "호흡 모니터링 기능 개폐",
        sleepMonitoring: "수면 모니터링 기능 개폐",
        struggle: "비정상적인 몸부림 상태 기능",
        RealTimeDataTransmission: "실시간 데이터 전송",
        SleepStateTransmission: "수면 상태 전송",
        SleepQualityAnalysis: "수면 질량 분석",
        SleepSynthesis: "수면 종합 상태",
        reportFormMode: "에스컬레이션 모드",
        reportForm: "에스컬레이션",
        detailInfo: "세부내용",
        once: "차",
        issueSetSeccess: "에스컬레이션 모드 설정 성공",
        changeErr: "상태 수정 실패",
        changeSuc: "상태 수정 성공",
    },
    fallRadar: {
        AverageBreathe: "평균 호흡",
        MeanHeartbeat: "평균 심장박동",
        NumberOfTurnovers: "몸 뒤집기 횟수",
        BodyActivityRatio: "큰 폭의 신체움직임 비율",
        NumberOfApnea: "호흡 잠시 멈춤 횟수",

        SleepQualityScore: "수면 품질 점수",
        TotalSleepDuration: "총 수면 시간",
        WakefulnessDuration: "깨어있는 시간 점유율",
        LightSleepDuration: "얕은 잠 시간 점유율",
        DeepSleepDuration: "깊은 잠 시간 점유율",
        TimeAwayFromBed: "침대에서 내린 시간",
        OutOfBedTimes: "침대에서 내린 횟수",
    },
    pay: {
        enterPackageName: "패키지 이름을 입력하십시오.",
        enterPackagePrice: "패키지 가격을 입력하십시오.",
        addPackage: "패키지 추가",
        PackageDetails: "패키지 상세 정보",
        DataUsage: "데이터",
        Expiration: "사용 기간",
        Model: "모델",
        Enable: "사용 가능",
        Disable: "사용 금지",
        PackageName: "패키지 이름",
        PackagePrice: "패키지 가격",
        PackageStatus: "패키지 상태",
        PackageType: "패키지 유형",
        audience: "패키지 대상",
        PackageUnit: "패키지 단위",
        Quantity: "패키지 수량",
        UpdateDate: "업데이트 날짜",
        Day: "일",
        Month: "월",
        Year: "년",
        day: "일",
        Item: "개",
        nameCannot: "패키지 이름은 비워둘 수 없습니다",
        priceCannot: "패키지 가격은 비워둘 수 없습니다",
        Associated: "관련 회사",
        Tariff: "요금 패키지 목록",
        Yuan: "원",
        newPackage: "신규 패키지",
        deletedPackageSuc: "패키지 삭제 성공",
        deletedPackageErr: "패키지 삭제 실패",
        ModifyPackage: "패키지 수정",
        addedPackage: "패키지 추가 성공",
        FailedAddPackage: "패키지 추가 실패",
        modifiedPackage: "패키지 수정 성공",
        modifyFailed: "패키지 수정 실패",
        confirmDelPackage: "이 패키지를 삭제하시겠습니까?",
        correctPrice: "제대로된 가격을 입력해 주세요.",
        noZero: "세트 가격은 0일 수 없습니다.",
        numNoZero: "세트 수량은 0일 수 없습니다.",
        "selectedDevice": "선택된 장치를 보기",
        "Associateddevice": "연결된 장치",
        "thisDevice": "이 장치에 요금을 연결하시겠습니까?",
        "canceldevice": "이 장치의 요금 연결을 취소하시겠습니까?",
        "UnselectDevice": "이 장치를 선택 해제하시겠습니까?",
        "selectdeviceFirst": "먼저 장치를 선택하세요",
        "Deviceselected": "장치 선택 완료",
        "Associatedselected": "연결된 장치 선택 완료",
        "modelList": "모델 리스트",
        "ViewDeviceM": "선택된 모델을 보기",
        "AssociatedDevice": "연결된 모델",
        "associateDevice": "이 모델에 요금을 연결하시겠습니까?",
        "cancelAssociation": "이 모델의 요금 연결을 취소하시겠습니까?",
        "selectModel": "먼저 모델을 선택하세요",
        "selectDevice": "모델 선택 완료",
        "associatedModel": "먼저 연결된 모델을 선택하세요",
        "AssociatedSelected": "연결된 모델 선택 완료",
        "cancelSelectModel": "이 모델 선택 취소하시겠습니까?",
        "uList": "단위 목록",
        "viewSeleteU": "선택된 단위를 보기",
        "glUnit": "연결된 단위",
        "enterUName": "단위 이름을 입력하세요",
        "sureAssociatU": "이 단위에 요금을 연결하시겠습니까?",
        "cancelAssociatU": "이 단위의 요금 연결을 취소하시겠습니까?",
        "cancelSelectU": "이 단위를 선택 취소하시겠습니까?",
        "selectU": "먼저 단위를 선택하세요",
        "selectedU": "단위 선택 완료",
        "selectAssociaU": "먼저 연결된 단위를 선택하세요",
        "selectedAssociaU": "연결된 단위 선택 완료",
        "viewSelectedUser": "선택된 사용자 보기",
        "gledUser": "연결된 사용자",
        "gledUserTips": "이 사용자에 요금제를 연결하시겠습니까?",
        "cancelGLU": "이 사용자의 요금제 연결을 취소하시겠습니까?",
        "loginName": "로그인 이름",
        "cancelSelectUser": "이 사용자의 선택을 취소하시겠습니까?",
        "selectUser": "먼저 사용자를 선택하세요",
        "selectedUser": "선택된 사용자",
        "glSelectUser": "먼저 연결된 사용자를 선택하세요",
        "glSelectedUser": "선택된 연결된 사용자",
        "inputON": "주문 번호를 입력하십시오",
        "orderN": "주문 번호",
        "orderState": "주문 상태",
        "orderPrice": "주문 가격",
        "orderNum": "주문 수량",
        "upDate": "수정 날짜",
        "pay": "결제",
        "orderInfo": "주문 정보",
        "orderGLD": "주문에 연결된 장치",
        "payPhoto": "결제 스냅샷 정보",
        "packageInfo": "패키지 정보",
        "orderName": "주문 이름",
        "submitPay": "결제 확인",
        "wx_sweep": "위챗으로 스캔하세요",
        "payEnd": "거래 완료",
        "paying": "거래 진행 중",
        "packageId": "패키지 ID",
        "orderNO": "주문 번호",
        "payInfo": "구매 전 정보",
        "payedInfo": "구매 후 정보",
        "clearSearch": "검색 조건이 지워졌습니다",
        "paySnapshot": "지불 스냅샷",
        "PackageList": "패키지 목록",
        "RefreshPackages": "패키지 갱신",
        "Price": "가격",
        "Enabled": "활성화",
        "Disabled": "비활성화",
        "SelectDate": "만료일 선택",
        "AddQueue": "결제 대기열 추가",
        "QueueList": "결제 대기열 목록",
        "OrderCode": "주문 번호",
        "ClearQueue": "결제 대기열 비우기",
        "SubmitOrder": "주문 제출",
        "enterQuantity": "패키지 수량을 입력하세요",
        "enterInteger": "정수를 입력하세요",
        "SelectedPackages": "패키지 선택 완료",
        "SelectPackage": "패키지를 선택하세요",
        "NoAvailable": "패키지 없음",
        "enterNumber": "숫자를 입력하세요",
        "Payment": "결제",
        "toShoppingCart": "이 장비를 장바구니에서 제거하시겠습니까?",
        "quantityEmpty": "패키지 수량을 입력해야 합니다",
        "Expired": "만료",
        "ExpiringSoon7": "곧 만료(7일)",
        "ExpiringSoon30": "곧 만료(30일)",
        "operationContinue": "이 작업은 결제 대기열을 비울 것입니다. 계속하시겠습니까?",
        "clearedSucc": "결제 대기열 성공적으로 비워졌습니다",
        "FailedQueue": "결제 대기열 비우기 실패",
        "SelectShopCart": "장바구니에 추가할 장비를 선택하세요",
        "DeviceID:": "번호:",
        "FailedAddCart": "장비를 장바구니에 추가하는 데 실패했습니다",
        "shopAddedSucce": "장바구니에 성공적으로 추가했습니다",
        "TDYHNBATTSC": "선택한 장비는 아직 장바구니에 추가되지 않았습니다. 장바구니에 추가하시겠습니까?",
        "NPSUTSO": "패키지를 선택하지 않았으므로 주문을 제출할 수 없습니다.",
        "PLIEUTSO": "결제 대기열 목록이 비어 있으므로 주문을 제출할 수 없습니다.",
        "billDevice": "장비 목록을 선택하시겠습니까?",
        "price500": "세트 요리의 가격은 500원보다 커서는 안 된다",
        "Pleaseenter": "입력하십시오",
        "PaymentTime": "결제 시간",
        "PaySnapshotD": "결제 스냅샷 세부 사항",
        "enterOrderNumber": "주문 번호를 입력하십시오",
        "enterOpenid": "openid를 입력하십시오",
        "selectTradingType": "거래 유형을 선택하십시오",
        "selectTradingStatus": "거래 상태를 선택하십시오",
        "OfficialPayment": "공중 호출 결제",
        "Apppayment": "앱 결제",
        "QRCodePayment": "결제 QR 코드 결제",
        "H5Payment": "H5 결제",
        "FacePayment": "얼굴 인식 결제",
        "PaymentSucc": "결제 성공",
        "Refundinitiat": "환불 처리",
        "NotPaid": "결제 미완료",
        "Closed": "종료됨",
        "Cancelled": "취소됨",
        "UserPaying": "사용자 결제 중",
        "PaymentFailed": "결제 실패",
        "MerchantID": "상점 번호",
        "WeChatOrder": "웨이챗 결제 번호",
        "BankType": "은행 유형",
        "OrderCurrency": "주문 통화",
        "MerchantNumber": "상점 시스템 내부 주문 번호",
        "UserOpenid": "사용자 openid",
        "PaymentCurrency": "결제 통화",
        "UserAmount": "사용자 결제 금액",
        "CallbackSummary": "콜백 요약",
        "TransactionType": "거래 유형",
        "TransactionStatus": "거래 상태",
        "TotalAmount": "주문 총 금액",
        "statusDescri": "거래 상태 설명",
        "transactionTime": "성공 거래 시간",
        "scanPay": "스캔 결제",
        "RMB": "인민폐",
        "createOrder": "원클릭 주문 생성",
        "forbiddenPackage": "패키지가 비활성화됨",
        "orderDetail": "주문서 상세정보",
        clear: "제거",
    },

    deviceDetail: {
        "Default": "기본",
        "LivingRoom": "거실",
        "Bedroom": "침실",
        "Bathroom": "화장실",
        "HumanStatus": "인체 존재 기능 상태",
        "FallStatus0": "넘어짐 감지 기능 상태",
        "StayStatusFn": "체류 기능 상태",
        "DefenseTime": "방어 시간",
        "sureModifyStatus": "이 상태를 수정하시겠습니까",
        "FallRadar": "넘어짐 레이더",
        "SettingRange1": "설정 범위 0~3",
        "SettingRange2": "설정 범위 60~3600",
        "SettingRange3": "설정 범위 150~400",
        "SettingRange4": "설정 범위 5~180",
        "VersionAgreement": "버전 협약",
        "ManufacturerID": "제조업체 식별",
        "ProgramVersion": "프로그램 버전",
        "HumanPresence": "인체 존재",
        "ActivityStatus": "활동 상태",
        "FallStatus": "넘어짐 상태",
        "StayStatus": "체류 상태",
        "UsageScenario": "사용 시나리오",
        "StayTime": "체류 시간",
        "InstallationHeight": "설치 높이",
        "FallDuration": "넘어짐 지속 시간",
        "FallSensitivity": "넘어짐 감도",
        "NoFall": "넘어지지 ",
        "Fall": "넘어짐",
        "NoStaticStay": "정지 체류 없음",
        "StaticStay": "정지 체류 있음",
        "Second": "초",
        "Minute": "분",
        "Hour": "시간",
        "Axis": "축",
        "Trend": "추세",
        "minute5Tips": "시간 단위가 분일 경우, 주기값은 5 미만일 수 없음",
        "NoTrend": "농도 추세 데이터가 없음",
        "ConcentTrend": "농도 추세",
        "Threshold": "고보고 저보고 임계값 설정",
        "HighThreshold": "고보고 임계값",
        "LowThreshold": "저보고 임계값",
        "CameraList": "카메라 목록",
        "AddCamera": "카메라 추가",
        "ViewTrend": "농도 추세 보기",
        "ViewCamera": "카메라 보기",
        "sureDelCamera": "카메라를 삭제하시겠습니까?",
        "SensosSettings": "센서 감도 설정",
        "settingCamera": "카메라 매개변수 설정",
        "previous": "이전",
        "next": "다음",
        "ThresholdSet": "임계값 설정",
        "failCommand": "명령어 저장 실패",
        "succCommand": "명령어 저장 성공, 장치 실행 대기중",
        "Gastype": "기체 타입",
        "repectDate": "날짜를 중복하다",
        "UnbindDevices": "한국어: 모든 장치를 해제하기",
        "LinkedSelf": "한국어: 연동 소리 및 광 자체검사를 실행하기",
        "TurnRelay": "한국어: 알고리즘 제어 릴레이를 켜기",
        "TurnOffRelay": "한국어: 알고리즘 제어 릴레이를 끄기",
        "EnableRelay": "한국어: 원격 릴레이를 켜기",
        "DisableRelay": "한국어: 원격 릴레이를 끄기",
        "DefenseID": "방구역 ID",
        "DefenseNumber": "방구역 번호",
        "DTName": "장비 유형 이름",
        "DN": "장비 유형 번호",
        "DSName": "장비 상태 이름",
        "selfInspection": "하상 방구역 자검 발급",
        "erasure": "하상 방구역 소음 제거",
        "delarea": "방구역 삭제",
        "delareaTip": "이 작업은 방구역을 삭제합니다. 계속하시겠습니까?",
        "selfInspectionTip": "이 작업은 방구역에 자검 작업을 수행합니다. 계속하시겠습니까?",
        "earsureTip": "이 작업은 방구역에 소음 제거 작업을 수행합니다. 계속하시겠습니까?",
        "height_input": "입력하시겠습니까? 높이 경보값",
        "low_input": "입력하시겠습니까? 낮이 경보값",
        "week256": "주기값은 256을 초과할 수 없습니다.",
        "weekUnit": "주기 단위를 선택하십시오.",
        "weekNumber": "주기는 정수이어야 합니다",
        "valvePit": "지하 밸브 잣",
        "enterSearch": "Enter를 눌러 검색",
    },
    cameraImg: {
        "EventCode": "이벤트 코드",
        "selectCode": "이벤트 코드를 선택하십시오",
        "TagName": "태그 이름",
        "selectTagName": "태그 이름을 선택하십시오",
        "enterCode": "장치 코드를 입력하십시오",
        "PaymentTime": "결제 시간",
        "Batchmodify": "이미지 태그를 대량 수정하십시오",
        "ModifyTag": "태그 수정",
        "ModifyImageTag": "이미지 태그 수정",
        "Unknown": "알 수 없음",
        "EVRA": "전기 자동차 반복 경보",
        "Online": "온라인",
        "GarbageOverflow": "쓰레기 넘침",
        "GarbageFull": "쓰레기 가득",
        "UnknownEvent": "알 수 없는 이벤트",
        "NISFM": "수정할 이미지를 선택하지 않음",
        "imgSize5MB": "사진은 5mb보다 크다, 현재 그림 크기는 다음과 같습니다",
        "smoke433Alarm": "433 담배감지경보",
    },
    cameraProduct: {
        "AddTag": "태그 추가",
        "DYWTDTTT": "이 태그 유형을 삭제하시겠습니까?",
        "ModifyTag": "태그 수정",
        "TNCBE": "태그 이름은 비워 둘 수 없습니다.",

        "completeStandardID": "국가 표준 ID를 완전히 입력해 주세요",
        "NationalStandardID": "국가 표준 ID",
        "VersionName": "버전 이름",
        "UpgradeRequired": "업그레이드 필요한가요 ?",
        "UpgradeResult": "업그레이드 결과",
        "UpgradeList": "업그레이드 대기 리스트 보기",
        "UpgradeDevices": "선택한 장치를 업그레이드하세요",
        "UpgradeLogs": "업그레이드 로그",
        "UpgradeOrders": "업그레이드 대기 작업 명세서",
        "Upgrade": "업그레이드",
        "addDeviceFirst": "먼저 장치를 추가해 주세요",
        "selectupgrade": "업그레이드할 장치를 선택해 주세요!",
        "cannotEmptyDevice": "장치 코드는 비워 두지 마세요",
        "CurrentVersion": "현재 버전",
        "PendingUpgradeList": "업그레이드 대기 리스트",
        "isAddUpgradeList": "업그레이드 리스트에 추가하시겠습니까 ?",
        "AddUpgradeList": "업그레이드 리스트에 추가",
        "NotUpgrade": "업그레이드 하지 않음",
        "PendingUpgrade": "업그레이드 대기중",
        "UpgradeFailed": "업그레이드 실패",
        "emptyChipId": "ChipId는 비워 두지 마세요",
        "emptyModelId": "모델 Id는 비워 두지 마세요",
        "emptyVersionId": "버전 Id는 비워 두지 마세요",
        "enterVersionName": "버전 이름을 입력해 주세요",
        "enterChipId": "ChipId를 입력해 주세요",
        "uNotC": "업그레이드 대기 리스트에는 현재 내용이 없습니다!",
        "deleteVersion": "버전을 삭제하시겠습니까",
        "upgradeState": "업그레이드 상태",
        "upgradeDate": "업그레이드 날짜",
        "getDataFial": "데이터 획득 실패",
        "MD5Name": "D5 이름을 입력하십시오.",
        "enterPartition": "파티션 이름을 입력하십시오.",
        "selectModel": "모델 이름을 선택하십시오.",
        "emptyAttachment": "첨부 파일은 비워둘 수 없습니다.",
        "VersionID": "버전 ID",
        "Partition": "파티 션",
        "emptyVersion": "버전 이름은 비워 두지 마세요",
        "emptyMD5": "MD5는 비워 두지 마세요",
        "searchAll": "모든 것을 조회하다",

    },

    other: {
        deoval: "제거 확인",
        location: "위치 분포",
    }
    
}
